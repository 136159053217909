<!--血压统计-->
<template>
  <div>

    <el-card style="margin: 20px;">
      <el-form ref="form" label-width="70px" size="small">
        <el-form-item label="学校：">
          <ZxxSchool-select ref="schools" v-model="form.schoolId"></ZxxSchool-select>
        </el-form-item>
        <el-form-item label="年级：">
          <el-input v-model="form.grade"></el-input>
        </el-form-item>
        <el-form-item label="班级：">
          <el-input v-model="form.classRoomNo"></el-input>
        </el-form-item>
        <el-form-item label="性别：">
          <sex-select v-model="form.sex"></sex-select>
        </el-form-item>
        <el-form-item label="年度：">
          <year-select v-model="form.year"></year-select>
        </el-form-item>
        <el-col :lg="5" :xl="4">
          <el-form-item label="统计类型：" label-width="100px" style="width: 100%;">
            <statistical-type-select v-model="form.groupBy" :clearable="false"
                                     @updateValue="search"></statistical-type-select>
          </el-form-item>
        </el-col>

        <div style="display: flex; margin-left: auto">
          <div>
            <el-button v-show="$hasPermission('blood.list')" size="small" type="primary" @click="search">查 询
            </el-button>
            <el-button v-show="$hasPermission('blood.list')" size="small" type="primary" @click="exportExcel">导 出
            </el-button>
            <el-button v-show="$hasPermission('blood.list')" size="small" type="primary" @click="printTable">
              打印当前页
            </el-button>
          </div>
        </div>

      </el-form>

    </el-card>
    <el-card style=" margin: 20px">
      <el-table
          id="exportTab"
          :key="type"
          v-loading="loading"
          :data="formList2"
          border

          style="width: 100%"
      >
        <el-table-column
            :label="type"
            prop="_id"
        >
          <template slot-scope="scope">
            <template v-if="form.groupBy === '$sex'">
              <div v-if="scope.row._id === 0">男</div>
              <div v-if="scope.row._id === 1">女</div>
              <div v-if="scope.row._id === '合计'">合计</div>
            </template>
            <template v-else>
              <div>{{ scope.row._id }}</div>
            </template>
          </template>

        </el-table-column>
        <el-table-column
            align="left"
            label="受检人数"
            prop="total"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.r001Sum + scope.row.r002Sum + scope.row.r003Sum }}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="偏低">
          <el-table-column
              label="人数"
              prop="r001Sum"
          >
          </el-table-column>
          <el-table-column
              label="率(%)"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r001Rate |percent }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="正常">
          <el-table-column
              label="人数"
              prop="r002Sum"
          >
          </el-table-column>
          <el-table-column
              label="率(%)"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r002Rate |percent }}</div>
            </template>
          </el-table-column>

        </el-table-column>
        <el-table-column
            label="偏高">
          <el-table-column
              label="人数"
              prop="r003Sum"
          >
          </el-table-column>
          <el-table-column
              label="率(%)"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r003Rate | percent }}</div>
            </template>
          </el-table-column>

        </el-table-column>


      </el-table>


      <div style="color: rgba(80, 155, 248, 1);font-size: 11px; margin-top: 20px">
      </div>
    </el-card>
  </div>
</template>

<script>

import ZxxSchoolSelect from "@/components/ZxxSchoolSelect"
import gradeSelect from "@/components/gradeSelect"
import classRoomNoSelect from "@/components/classRoomNoSelect"
import sexSelect from "@/components/sexSelect"
import yearSelect from "@/components/yearSelect"
import statisticalTypeSelect from "@/components/statisticalTypeSelect"


import * as scriptApi from "@/api/gen/scriptApi"

export default {
  name: "blood",
  components: {
    ZxxSchoolSelect,
    statisticalTypeSelect,
    gradeSelect,
    classRoomNoSelect,
    sexSelect,
    yearSelect,

  },
  data() {
    return {
      form: {
        id: "",
        schoolId: "",
        schoolType: "",
        idNo: "",
        grade: "",
        classRoomNo: "",
        name: "",
        birthDate: "",
        age: "",
        sex: "",
        studentId: "",
        contact: "",
        nationalityCode: "",
        cxType: "",
        checkDate: "",
        year: "",
        result: "",
        advice: "",
        groupBy: ""
      },

      name: '',
      scriptName: '',
      formList: [],


      isEditShow: false,
      isUpdateDialog: false,
      current: {},
      model: "",
      options: "",
      loading: false
    }
  },
  computed: {
    type() {
      if (this.form.groupBy === '$age') {
        return '年龄(岁)'
      }
      if (this.form.groupBy === '$sex') {
        return '性别'
      }
      if (this.form.groupBy === '$grade') {
        return '年级'
      }
    },
    formList2() {
      this.formList.forEach(item => {
        item.total = item.r001Sum + item.r002Sum + item.r003Sum
        item.r001Rate = item.total ? item.r001Sum / item.total : 0
        item.r002Rate = item.total ? item.r002Sum / item.total : 0
        item.r003Rate = item.total ? item.r003Sum / item.total : 0
      })
      var total = this._.sumBy(this.formList, 'total')
      var r001Sum = this._.sumBy(this.formList, 'r001Sum')
      var r002Sum = this._.sumBy(this.formList, 'r002Sum')
      var r003Sum = this._.sumBy(this.formList, 'r003Sum')
      this.formList.push({
        "_id": '合计',
        total, r001Sum, r002Sum, r003Sum,
        r001Rate: total ? r001Sum / total : 0,
        r002Rate: total ? r002Sum / total : 0,
        r003Rate: total ? r003Sum / total : 0

      })
      return this.formList
    }

  },
  mounted() {


  },
  methods: {

    async search() {
      if (!this.form.schoolId) {
        this.$message.warning('请选择学校')
        return
      }
      await this.scriptList()

    },


    async scriptList() {
      this.loading = true
      let clone = this._.cloneDeep(this.form)
      if (!clone.schoolId) {
        clone.schoolId = this.$refs.schools.getItems().map(item => item.id).join(',')
      }

      if (clone.sex) {
        if (clone.sex == '男') {
          clone.sex = 0;
        } else {
          clone.sex = 1;
        }
      }
      clone.field = 'r099'
      this.formList = await scriptApi.execute({name: '全部统计2.groovy', params: clone})

      this.formList = this._.chain(this.formList).sortBy(x => parseFloat(x._id)).value()
      this.$sortByGrade(this.formList)
      this.loading = false

    },


    exportExcel() {
      var sex = this.form.sex
      var grade = this.form.grade
      var classRoomNo = this.form.classRoomNo
      var query = []
      var queryStr = ""
      if (sex) {
        query.push(sex)
      }
      if (grade) {
        query.push(grade)
      }
      if (classRoomNo) {
        query.push(classRoomNo)
      }
      if (query.length > 0) {
        queryStr = query.join("-")
      }
      if (queryStr) {
        queryStr = queryStr + "-"
      }
      this.$exportToExcel(`${this.$store.getters.organizationName}-${this.form.year}-${queryStr}${this.$storage.getStorage('pageName')}-${this.$dateString(this.$now())}.xlsx`, '#exportTab', queryStr)
    },

    printTable() {

      this.$print('#exportTab')
    },

  }

}
</script>

<style scoped>
.el-card .el-form {
  display: flex;
  flex-wrap: wrap;

}

/deep/ .el-form-item {
  width: 13%;
}


/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}
</style>
