<template>
  <div>
    <gatherBasis :cells="cells" :type="type">
      <template #column>
        <el-table-column align="center" header-align="center" label="检查人数" prop="allCount"/>
        <el-table-column align="center" header-align="center" label="视力正常">
          <el-table-column align="center" header-align="center" label="人数" prop="normal" :width="columnWidth"/>
          <el-table-column align="center" header-align="center" label="百分比" prop="normalRatio" :width="columnWidth"/>
        </el-table-column>
        <el-table-column align="center" header-align="center" label="视力不良">
          <el-table-column align="center" header-align="center" label="人数" prop="sum" :width="columnWidth"/>
          <el-table-column align="center" header-align="center" label="百分比" prop="sumRatio" :width="columnWidth"/>
        </el-table-column>
        <el-table-column align="center" header-align="center" label="视力不良分布">
          <el-table-column align="center" header-align="center" label="低度视力低下" prop="r066" :width="columnWidth"/>
          <el-table-column align="center" header-align="center" label="百分比" prop="r066Ratio" :width="columnWidth"/>
          <el-table-column align="center" header-align="center" label="中度视力低下" prop="r067" :width="columnWidth"/>
          <el-table-column align="center" header-align="center" label="百分比" prop="r067Ratio" :width="columnWidth"/>
          <el-table-column align="center" header-align="center" label="高度视力低下" prop="r068" :width="columnWidth"/>
          <el-table-column align="center" header-align="center" label="百分比" prop="r068Ratio" :width="columnWidth"/>
        </el-table-column>
      </template>
    </gatherBasis>
  </div>

</template>
<script>
import gatherBasis from "../components/gatherBasis.vue"
/*视力评价等级*/
export default {
  name: "gatherEyesight",
  components: {
    gatherBasis
  },
  data() {
    return {
      columnWidth: "100px",
      type: "eyesight",
      cells: [
        {prop: "r066", name: '低度视力低下'},
        {prop: "r067", name: '中度度视力低下'},
        {prop: "r068", name: '高度度视力低下'},
      ]
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
<style scoped>

</style>