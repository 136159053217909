<!--其他常见病检出情况-->
<template>
  <div>

    <el-card style="margin: 20px;">
      <el-form ref="form" label-width="70px" size="small">

        <el-form-item label="年度：">
          <year-select v-model="form.year"></year-select>
        </el-form-item>
        <el-form-item label="统计类型：" label-width="100px">
          <statistical-type-select v-model="form.groupBy" :clearable="false"
                                   @updateValue="search"></statistical-type-select>
        </el-form-item>
        <el-form-item label="学校：">
          <ZxxSchool-select ref="schools" v-model="form.schoolId"></ZxxSchool-select>
        </el-form-item>

        <div style="margin-left: auto">
          <el-button v-show="$hasPermission('synthetical.list')" size="small" type="primary" @click="search">查 询
          </el-button>
          <el-button v-show="$hasPermission('synthetical.export')" size="small" type="primary" @click="exportExcel">导
            出
          </el-button>
          <el-button v-show="$hasPermission('synthetical.print')" size="small" type="primary" @click="printTable">打印当前页
          </el-button>
        </div>

      </el-form>

    </el-card>
    <el-card style=" margin: 20px">
      <el-table
          id="exportTab"
          v-loading="loading"
          :data="formList"
          :span-method="spanMethod"

          border

          style="width: 100%"
      >
        <el-table-column
            label="学校"
            prop="_id[0]"
            width="150px"
        >

          <template slot-scope="scope">
            <div>{{ findSchoolName(scope.row._id[0]) }}</div>
          </template>


        </el-table-column>
        <el-table-column
            :key="type"
            :label="type"

            prop="_id[1]"
        >
          <template slot-scope="scope">
            <template v-if="form.groupBy === '$sex'">
              <div v-if="scope.row._id[1] === 0">男</div>
              <div v-else-if="scope.row._id[1]=== 1">女</div>
              <div v-else>{{ scope.row._id[1] }}</div>
            </template>
            <template v-else>
              <div>{{ scope.row._id[1] }}</div>
            </template>

          </template>
        </el-table-column>
        <el-table-column
            label="受检人数"
            prop="total"
        >
        </el-table-column>
        <el-table-column
            label="身高"
        >
          <el-table-column
              label="上等"
              prop="r045Sum"
          >
          </el-table-column>
          <el-table-column
              label="中等"
              prop="r043Sum"
          >
          </el-table-column>
          <el-table-column
              label="下等"
              prop="r041Sum"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="体重"
        >
          <el-table-column
              label="上等"
              prop="r077Sum"
          >
          </el-table-column>
          <el-table-column
              label="中等"
              prop="r076Sum"
          >
          </el-table-column>
          <el-table-column
              label="下等"
              prop="r075Sum"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="结核菌素"
        >
          <el-table-column
              label="结核菌素阴性"
              prop="r017Sum"
          >
          </el-table-column>
          <el-table-column
              label="结核菌素阳性"
              prop="r018Sum"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="营养不良"
            prop="r003Sum"
        >
        </el-table-column>
        <el-table-column
            label="肥胖"
            prop="r005Sum"
        >
        </el-table-column>
        <el-table-column
            label="视力不良"
            prop="r073Sum"
        >
        </el-table-column>
        <el-table-column
            label="牙齿情况"
        >
          <el-table-column
              label="龋患人数"
              prop="r074Sum"
          >
          </el-table-column>
          <el-table-column
              label="龋失牙数"
          >
            <template slot-scope="scope">
              <!-- 龋失牙=恒牙失牙数(r013)+乳牙失牙数(r035) -->
              <div>{{ parseFloat(scope.row.r013Sum + scope.row.r035Sum) }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="已补牙数"
          >
            <template slot-scope="scope">
              <!--  已补牙=恒牙补牙数(r14)+乳牙补牙数(r36)     -->
              <div>{{ parseFloat(scope.row.r014Sum + scope.row.r036Sum) }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="沙眼"
        >
          <el-table-column
              label="可疑沙眼"
              prop="r024Sum"
          >
          </el-table-column>
          <el-table-column
              label="沙眼"
              prop="r006Sum"
          >
          </el-table-column>

        </el-table-column>
        <el-table-column
            label="贫血"
        >
          <el-table-column
              label="实查人数"
              prop="total"
          >
          </el-table-column>
          <el-table-column
              label="阳性人数"
              prop="r030Sum"

          >
          </el-table-column>

        </el-table-column>
        <el-table-column
            label="蛔虫"
        >
          <el-table-column
              label="实查人数"
              prop="total"
          >
          </el-table-column>
          <el-table-column
              label="阳性人数"
              prop="r023Sum"
          >
          </el-table-column>

        </el-table-column>


      </el-table>


    </el-card>
  </div>
</template>
<script>

import ZxxSchoolSelect from "@/components/ZxxSchoolSelect"
import gradeSelect from "@/components/gradeSelect"
import classRoomNoSelect from "@/components/classRoomNoSelect"
import sexSelect from "@/components/sexSelect"
import yearSelect from "@/components/yearSelect"
import statisticalTypeSelect from "@/components/statisticalTypeSelect"


import * as scriptApi from "@/api/gen/scriptApi"

export default {
  name: "synthetical",
  components: {
    ZxxSchoolSelect,
    statisticalTypeSelect,
    gradeSelect,
    classRoomNoSelect,
    sexSelect,
    yearSelect,

  },
  data() {
    return {
      form: {
        id: "",
        schoolId: "",
        schoolType: "",
        idNo: "",
        grade: "",
        classRoomNo: "",
        name: "",
        birthDate: "",
        age: "",
        sex: "",
        studentId: "",
        contact: "",
        nationalityCode: "",
        cxType: "",
        checkDate: "",
        year: "",
        result: "",
        advice: "",
        groupBy: ''
      },

      name: '',
      scriptName: '',
      formList: [],


      isEditShow: false,
      isUpdateDialog: false,
      current: {},
      model: "",
      options: "",

      loading: false
    }
  },
  computed: {
    type() {
      if (this.form.groupBy === '$age') {
        return '年龄(岁)'
      }
      if (this.form.groupBy === '$sex') {
        return '性别'
      }
      if (this.form.groupBy === '$grade') {
        return '年级'
      }
    }

  },
  mounted() {


  },
  methods: {

    async search() {
      if (!this.form.schoolId) {
        this.$message.warning('请选择学校')
        return
      }
      await this.scriptList()

    },


    async scriptList() {
      this.loading = true
      let clone = this._.cloneDeep(this.form)
      if (!clone.schoolId) {
        clone.schoolId = this.$refs.schools.getItems().map(item => item.id).join(',')
        clone.groupBy = ["$schoolId", clone.groupBy];
      } else {
        clone.groupBy = ["$schoolId", clone.groupBy];
      }

      this.formList = await scriptApi.execute({name: '全部统计.groovy', params: clone})


      let tmp = this._.chain(this.formList).groupBy(x => x._id[0]).mapValues(x => {
        x.sort((a, b) => {

          if (this._.isNumber(a._id[1]) && this._.isNumber(b._id[1])) {

            return a._id[1] - b._id[1];
          } else {

            try {
              return a?._id[1]?.localeCompare(b._id[1]);

            } catch (e) {
              console.log(e);
              return 0;
            }
          }

        });
        return x
      }).mapValues(x => {

        if (this._.isEmpty(x)) {
          return x;
        }


        let last = {};

        Object.keys(x[0]).forEach(
            k => {
              if (k === '_id') {
                return;
              }

              last[k] = this._.chain(x).sumBy(k).value();

            }
        )
        let tmp = [...x];
        this.$sortByGrade(tmp, x => x._id[1]);

        last._id = [x[0]._id[0], "合计"]
        tmp.push(last);

        return tmp;
      }).mapValues(x => {


        x[0].sholdSpan = true;
        x[0].span = x.length;
        return x;

      })


          .values().flatten().value();


      this.formList = tmp

      this.loading = false

    },


    exportExcel() {
      this.$exportToExcel(`${this.$store.getters.organizationName}-${this.form.year}-${this.$storage.getStorage('pageName')}-${this.$dateString(this.$now())}.xlsx`, '#exportTab')
    },
    printTable() {

      this.$print('#exportTab')
    },

    findSchoolName(id) {
      if (id === '合计') {
        return id;
      }
      return this.$refs.schools?.getItems()?.filter(x => x.id === id)[0]?.name;
    },


    spanMethod(obj) {
      let {
        row,
        column,
        rowIndex,
        columnIndex
      } = obj;
      // console.log(row, column, rowIndex, columnIndex)

      if (columnIndex != 0) {
        // console.log("不是第一列");
        return [1, 1];
      }

      if (row.sholdSpan) {
        // console.log("开始合并");
        return [row.span, 1]
      } else {
        return [0, 0]
      }
      return [1, 1];

    },


  }

}
</script>

<style scoped>
.el-card .el-form {
  display: flex;
  flex-wrap: wrap;

}


/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}
</style>
