<template>
  <div>
    <el-card style="margin: 20px;">
      <el-form ref="form" :inline="true" label-width="70px" size="small">
        <el-form-item label="年度：">
          <div style="width: 80px;display: inline-block;">
            <year-select v-model="form.yearStart"></year-select>
          </div>
          <div style="display: inline-block;padding: 0px 5px;">
            至
          </div>
          <div style="width: 80px;display: inline-block;">
            <year-select v-model="form.yearEnd"></year-select>
          </div>
        </el-form-item>
        <el-form-item label="统计类型：" label-width="100px">
          <div style="width: 120px;display: inline-block;">
            <statistical-type-select v-model="form.groupType" :clearable="false"
                                     @updateValue="search"></statistical-type-select>
          </div>
        </el-form-item>
        <el-form-item label="学校：">
          <div style="width: 200px;display: inline-block;">
            <ZxxSchool-select ref="schools" v-model="form.schoolId"></ZxxSchool-select>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="search">查 询</el-button>
          <el-button size="small" type="primary" @click="exportExcel">导 出</el-button>
          <el-button size="small" type="primary" @click="printTable">打 印</el-button>

        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table
          id="exportTab"
          v-loading="loading"
          :data="formList"
          :span-method="spanMethod"
          border
      >
        <el-table-column align="left" header-align="center" label="学校" prop="schoolName" width="120px"/>
        <el-table-column :label="typeName" align="center" header-align="center" prop="groupName"
                         width="120px"/>
        <slot name="column"></slot>
      </el-table>
    </el-card>
  </div>

</template>
<script>
import ZxxSchoolSelect from "@/components/ZxxSchoolSelect"
import sexSelect from "@/components/sexSelect"
import yearSelect from "@/components/yearSelect"
import statisticalTypeSelect from "@/components/statisticalTypeSelect"

import * as physicalGatherApi from "@/api/gen/physicalGatherApi"

export default {

  components: {
    ZxxSchoolSelect,
    statisticalTypeSelect,
    sexSelect,
    yearSelect,
  },
  props: {
    type: "",
    cells: []
  },
  data() {
    return {
      form: {
        type: "",
        cells: [],
        schoolId: '',
        groupType: '$grade',
        yearStart: '',
        yearEnd: '',
      },
      typeName: '',
      formList: [],
      loading: false
    }
  },

  mounted() {
    this.form.cells = [];
    for (let i = 0; i < this.cells.length; i++) {
      this.form.cells.push(this.cells[i].prop);
    }
    this.form.type = this.type;
    this.form.groupType = "$grade";
    //this.form.yearStart = "2024";
    //this.form.yearEnd = "2024";
    //this.form.schoolId = "676dedcb7b972f4b654f9e96";
    this.typeName = this.getType();
  },
  methods: {
    async search() {
      if (!this.form.schoolId) {
        this.$message.warning('请选择学校')
        return
      }
      this.typeName = this.getType();
      await this.bindList();
    },
    getType() {
      if (this.form.groupType === '$age') {
        return '年龄(岁)'
      }
      if (this.form.groupType === '$sex') {
        return '性别'
      }
      if (this.form.groupType === '$grade') {
        return '年级'
      }
    },
    async bindList() {

      this.loading = true
      try {
        let clone = this._.cloneDeep(this.form)
        if (!clone.schoolId) {
          clone.schoolId = this.$refs.schools.getItems().map(item => item.id).join(',')
        }

        this.formList = await physicalGatherApi.getGatherData(clone)
      } finally {
        this.loading = false
      }
    },
    spanMethod(obj) {
      let {
        row,
        column,
        rowIndex,
        columnIndex
      } = obj;

      if (columnIndex === 0) {
        let rowspan = this.formList.length;
        if (rowIndex === 0) {
          return {
            rowspan: rowspan,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
    exportExcel() {
      let yearSpan = this.form.yearStart + "至" + this.form.yearEnd;
      this.$store.commit('setTableYear', yearSpan)
      this.$exportToExcel(`${this.$store.getters.organizationName}-${yearSpan}年-${this.$storage.getStorage('pageName')}-${this.$dateString(this.$now())}.xlsx`, '#exportTab')
    },
    printTable() {
      this.$print('#exportTab')
    },
  }
}
</script>
<style scoped>
/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}
</style>