<template>
  <div style="padding: 10px;">
    <el-card>
      <el-button type="primary" @click="setStudentBlood">整体更新血压统计标记位</el-button>
      <el-button type="primary" @click="insertCheckItemToP075">检查项配置-【眼位】内斜</el-button>
      <el-button type="primary" @click="insertCheckItemToP076">检查项配置--【眼位】垂直位斜</el-button>
      <el-button type="primary" @click="insertCheckItemToP077">检查项配置--【眼位】外斜</el-button>
      <el-button type="primary" @click="insertCheckItemToP078">检查项配置--【眼位】初步结果</el-button>
    </el-card>
  </div>
</template>
<script>
import * as datahandlerApi from "@/api/gen/datahandlerApi"


export default {
  name: "datahandler",
  data: function () {
    return {};
  },
  mounted() {
  },
  methods: {
    /**
     * 初始化中小学省收缩压与舒张压记录
     * @returns {Promise<void>}
     */
    setStudentBlood() {
      this.$confirm('确定执行吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await datahandlerApi.dhSetStudentBlood();
        this.$message.success('执行成功')
      }).catch((err) => {
        console.log(err)
      })
    },
    /**
     * 33cm眼位检查项配置-内斜
     * @returns {Promise<void>}
     */
    insertCheckItemToP075() {
      this.$confirm('确定执行吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await datahandlerApi.insertCheckItemToP075();
        this.$message.success('执行成功')
      }).catch((err) => {
        console.log(err)
      })
    },
    /**
     * 33cm眼位检查项配置-垂直位斜
     * @returns {Promise<void>}
     */
    insertCheckItemToP076() {
      this.$confirm('确定执行吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await datahandlerApi.insertCheckItemToP076();
        this.$message.success('执行成功')
      }).catch((err) => {
        console.log(err)
      })
    },
    /**
     * 33cm眼位检查项配置-外斜
     * @returns {Promise<void>}
     */
    insertCheckItemToP077() {
      this.$confirm('确定执行吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await datahandlerApi.insertCheckItemToP077();
        this.$message.success('执行成功')
      }).catch((err) => {
        console.log(err)
      })
    },
    /**
     * 33cm眼位检查项配置-初步结果
     * @returns {Promise<void>}
     */
    insertCheckItemToP078() {
      this.$confirm('确定执行吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {
        await datahandlerApi.insertCheckItemToP078();
        this.$message.success('执行成功')
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
<style scoped>

</style>