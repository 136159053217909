import { render, staticRenderFns } from "./statisticalTypeSelect.vue?vue&type=template&id=5d142413&scoped=true"
import script from "./statisticalTypeSelect.vue?vue&type=script&lang=js"
export * from "./statisticalTypeSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d142413",
  null
  
)

export default component.exports