import request from '@/utils/request'


/**
 * 初始化中小学省收缩压与舒张压记录
 * @returns {*}
 */
export function dhSetStudentBlood() {
    return request({
        url: '/datahandler/setStudentBlood', method: 'POST'
    })
}

/**
 * 33cm眼位检查项配置-【眼位】内斜
 * @returns {*}
 */
export function insertCheckItemToP075() {
    return request({
        url: '/datahandler/insertCheckItemToP075', method: 'POST'
    })
}

/**
 * 33cm眼位检查项配置-【眼位】垂直位斜
 * @returns {*}
 */
export function insertCheckItemToP076() {
    return request({
        url: '/datahandler/insertCheckItemToP076', method: 'POST'
    })
}

/**
 * 33cm眼位检查项配置-【眼位】外斜
 * @returns {*}
 */
export function insertCheckItemToP077() {
    return request({
        url: '/datahandler/insertCheckItemToP077', method: 'POST'
    })
}

/**
 * 33cm眼位检查项配置-【眼位】初步结果
 * @returns {*}
 */
export function insertCheckItemToP078() {
    return request({
        url: '/datahandler/insertCheckItemToP078', method: 'POST'
    })
}
