<template>
  <div>
    <gatherBasis :cells="cells" :type="type">
      <template #column>
        <el-table-column align="center" header-align="center" label="检查人数" prop="r078_count"/>
        <el-table-column align="center" header-align="center" label="正常">
          <el-table-column align="center" header-align="center" label="人数" prop="r078_zc"/>
          <el-table-column align="center" header-align="center" label="百分比" prop="r078_zc_ratio"/>
        </el-table-column>
        <el-table-column align="center" header-align="center" label="配合欠佳">
          <el-table-column align="center" header-align="center" label="人数" prop="r078_qj"/>
          <el-table-column align="center" header-align="center" label="百分比" prop="r078_qj_ratio"/>
        </el-table-column>
        <el-table-column align="center" header-align="center" label="(疑似)异常">
          <el-table-column align="center" header-align="center" label="人数" prop="r078_yc"/>
          <el-table-column align="center" header-align="center" label="百分比" prop="r078_yc_ratio"/>
        </el-table-column>

        <el-table-column align="center" header-align="center" label="内斜">
          <el-table-column align="center" header-align="center" label="人数" prop="r075_count"/>
          <el-table-column align="center" header-align="center" label="百分比" prop="r075_ratio"/>
        </el-table-column>
        <el-table-column align="center" header-align="center" label="垂直位斜">
          <el-table-column align="center" header-align="center" label="人数" prop="r076_count"/>
          <el-table-column align="center" header-align="center" label="百分比" prop="r076_ratio"/>
        </el-table-column>
        <el-table-column align="center" header-align="center" label="外斜">
          <el-table-column align="center" header-align="center" label="人数" prop="r077_count"/>
          <el-table-column align="center" header-align="center" label="百分比" prop="r077_ratio"/>
        </el-table-column>

      </template>
    </gatherBasis>
  </div>

</template>
<script>
import gatherBasis from "../components/gatherBasis.vue"
/*33cm眼位统计报表*/
export default {
  name: "gatherTropia",
  components: {
    gatherBasis
  },
  data() {
    return {
      type: "tropia",
      cells: []
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
<style scoped>

</style>