<template>
  <div>
    <gatherBasis :cells="cells" :type="type">
      <template #column>

        <el-table-column align="center" header-align="center" label="男">
          <el-table-column align="center" header-align="center" label="受检数" prop="boyCount"/>
          <el-table-column align="center" header-align="center" label="检出数" prop="boyAbnormal"/>
          <el-table-column align="center" header-align="center" label="检出率" prop="boyAbnormalToCount"/>
        </el-table-column>
        <el-table-column align="center" header-align="center" label="女">
          <el-table-column align="center" header-align="center" label="受检数" prop="girlCount"/>
          <el-table-column align="center" header-align="center" label="检出数" prop="girlAbnormal"/>
          <el-table-column align="center" header-align="center" label="检出率" prop="girlAbnormalToCount"/>
        </el-table-column>

        <el-table-column align="center" header-align="center" label="男女合并">
          <el-table-column align="center" header-align="center" label="受检数" prop="bgCount"/>
          <el-table-column align="center" header-align="center" label="检出数" prop="bgAbnormal"/>
          <el-table-column align="center" header-align="center" label="检出率" prop="bgAbnormalToCount"/>
        </el-table-column>


      </template>
    </gatherBasis>
  </div>

</template>
<script>
import gatherBasis from "../components/gatherBasis.vue"
/*龋齿检出率*/
export default {
  name: "gatherCariesSick",
  components: {
    gatherBasis
  },
  data() {
    return {
      type: "cariesSick",
      cells: []
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
<style scoped>

</style>