import XLSX from "sheetjs-style";
import FileSaver from 'file-saver'
import store from '@/store';

function processSheet(wb, sheetName, config) {
    let sheet = wb.Sheets[sheetName];
    let old = sheet['!merges']
    sheet['!merges'] = [];
    let maxWidth = {};
    let rowNum;
    let colNum;
    let range = XLSX.utils.decode_range(sheet['!ref']);
    for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
            let id = XLSX.utils.encode_cell({r: rowNum, c: colNum});
            let cell = sheet[id];
            if (!cell) {
                sheet[id] = {t: "s", v: ""};
                cell = sheet[id];
            }

            if (cell.t === 'z') {
                cell.t = 's';
            }


            maxWidth[colNum] = Math.max(maxWidth[colNum] ? maxWidth[colNum] : 0, getTextWidth(cell.v, "11pt 宋体"));

            cell.s = {
                alignment: {

                    vertical: "center",
                    horizontal: "center",
                    wrapText: false,

                },
                font: {

                    name: "宋体",
                    sz: "11",
                    bold: false,
                },
                border: {
                    top: {
                        style: 'thin',
                        color: {rgb: "00000000"}

                    },
                    bottom: {
                        style: 'thin',
                        color: {rgb: "00000000"}

                    },
                    left: {
                        style: 'thin',
                        color: {rgb: "00000000"}

                    },

                    right: {
                        style: 'thin',
                        color: {rgb: "00000000"}

                    },

                }

            };

        }
    }
    for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
        if (!sheet['!cols']) {
            sheet['!cols'] = [];
        }
        sheet['!cols'].push({
            wpx: maxWidth[colNum]
        })
    }
    sheet['!merges'] = old;
    let cur = XLSX.utils.decode_range(sheet["!ref"])
    cur.e.r += 2

    sheet["!ref"] = XLSX.utils.encode_range(cur)
    let cell_address = {c: 0, r: cur.e.r}
    var queryStr = ""
    if (config) {
        queryStr = config;
        queryStr = queryStr.replaceAll("-", " ")
    }

    sheet[XLSX.utils.encode_cell(cell_address)] = {
        t: "s",
        v: `体检机构:${store.state.tableName} 所属学校:${store.state.tableSchoolName} 体检年份:${store.state.tableYear} ${queryStr}`
    }

}

function getTextWidth(text, font) {
    // re-use canvas object for better performance
    let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
}

function process(wb, config) {

    wb.SheetNames.forEach(x => processSheet(wb, x, config))

}

/**
 * @param {*} fileName
 * @param {string} id
 */
function exportToExcel(fileName = 'demo.xlsx', id = '#exportTable', config) {
    //表名
    let xlsxParam = {raw: true}
    let wb = XLSX.utils.table_to_book(document.querySelector(id), xlsxParam)
    saveWb(wb, fileName, config);
    //store.commit('clearTable')
}

function saveWb(wb, fileName, config) {

    process(wb, config);
    let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'})
    try {
        FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), fileName)
    } catch (e) {
        // if (typeof console !== 'undefined') console.log(e, wbout)
    }

}

export default {


    install(Vue) {
        Vue.prototype.$exportToExcel = exportToExcel;
        Vue.prototype.$XLSX = XLSX;
        Vue.prototype.$FileSaver = FileSaver;
        Vue.prototype.$SaveWB = saveWb;
    }

}
