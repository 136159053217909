<template>
  <div>
    <el-card style=" margin: 20px 20px">
      <el-form inline ref="form" :model="form" inline  size="mini">
        <el-form-item label="学校：">
          <ZxxSchool-select ref="schools" v-model="form.schoolId"></ZxxSchool-select>
        </el-form-item>
        <el-form-item label="姓名：">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="年级：">
          <el-input v-model="form.grade"></el-input>
        </el-form-item>
        <el-form-item label="班级：">
          <el-input v-model="form.classRoomNo"></el-input>
        </el-form-item>

        <el-form-item label="性别：">
          <data-select v-model="form.sex" parent-code="Gender"></data-select>
        </el-form-item>
        <el-form-item label="年龄：">
         <el-input v-model="form.age" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="体检年度：">
          <year-select v-model="form.year" placeholder="请输入"></year-select>
        </el-form-item>
        <el-form-item label="民族：">
          <nationality-select v-model="form.nationalityCode"></nationality-select>
        </el-form-item>

        <el-form-item label="检测日期：" label-width="100px">
          <el-date-picker
              v-model="date"
              :default-time="['00:00:00', '23:59:59']"
              end-placeholder="结束时间"
              range-separator="-"
              start-placeholder="开始时间"
              type="daterange"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="dateChange"

          >
          </el-date-picker>
        </el-form-item>

        <el-form-item style="margin-left: auto">
          <div style="display: flex; justify-content: flex-end">
            <el-button v-show="$hasPermission('medicalInformation.list')" type="primary" @click="list()">查 询</el-button>
            <el-button @click="empty">清 空</el-button>
          </div>
        </el-form-item>

      </el-form>

      <div style="display: flex; justify-content: space-between; margin: 20px 0 ">
        <div>
          <el-button size="medium" v-show="$hasPermission('medicalInformation.create') && $store.getters.inDate>=0"
                     type="primary"
                     @click="addDialogShow">+ 新增
          </el-button>
          <el-button size="medium" v-show="$hasPermission('medicalInformation.delete') && $store.getters.inDate>=0"
                     @click="remove">删除
          </el-button>
          <el-button size="medium" v-show="$hasPermission('medicalInformation.tjInfoBatch') && $store.getters.inDate>=0"
                     @click="toHealthFormBatch">批量打印体检表
          </el-button>
        </div>
        <div>
          <el-popover
              placement="bottom"
              width="200"
              trigger="click"
          >


            <el-checkbox-group v-model="checkboxGroup">
              <div style="display: flex; flex-direction: column; height: 50vh; overflow: auto">


                <el-checkbox checked label="schoolId">学校</el-checkbox>
                <el-checkbox checked label="grade">年级</el-checkbox>
                <el-checkbox checked label="classRoomNo">班级</el-checkbox>
                <el-checkbox checked label="name">姓名</el-checkbox>
                <el-checkbox checked label="sex">性别</el-checkbox>
                <el-checkbox checked label="nationalityCode">民族</el-checkbox>
                <el-checkbox checked label="studentId">学号</el-checkbox>
                <el-checkbox checked label="idNo">身份证号</el-checkbox>
                <el-checkbox checked label="birthDate">出生年月日</el-checkbox>
                <el-checkbox checked label="contact">联系电话</el-checkbox>
                <el-checkbox checked label="checkDate">检测日期</el-checkbox>
                <el-checkbox checked label="year">体检年度</el-checkbox>
                <el-checkbox checked label="age">年龄</el-checkbox>
                <el-checkbox checked label="cxType">城乡</el-checkbox>
                <el-checkbox checked label="p001">营养状况</el-checkbox>
                <el-checkbox checked label="p002">身高（cm）</el-checkbox>
                <el-checkbox checked label="sgInfo">身高信息</el-checkbox>
                <el-checkbox checked label="p003">体重（kg）</el-checkbox>
                <el-checkbox checked label="tzInfo">体重信息</el-checkbox>
                <el-checkbox checked label="p004">肺活量（ml）</el-checkbox>
                <el-checkbox checked label="p005">血压mmHg/收缩</el-checkbox>
                <el-checkbox checked label="p005Sub">血压mmHg/舒张</el-checkbox>
                <el-checkbox checked label="p006">胸围（cm）</el-checkbox>
                <el-checkbox checked label="p007">腰围（cm）</el-checkbox>
                <el-checkbox checked label="p009">视力/右</el-checkbox>
                <el-checkbox checked label="p009Sub">视力/左</el-checkbox>
                <el-checkbox checked label="p010">视力新发病</el-checkbox>
                <el-checkbox checked label="p011">矫正视力/右"</el-checkbox>
                <el-checkbox checked label="p011Sub">矫正视力/左"</el-checkbox>
                <el-checkbox checked label="p012">右眼球镜</el-checkbox>
                <el-checkbox checked label="p012Sub">右眼球镜值</el-checkbox>
                <el-checkbox checked label="p013">左眼球镜</el-checkbox>
                <el-checkbox checked label="p013Sub">左眼球镜值</el-checkbox>
                <el-checkbox checked label="p014">右眼柱镜</el-checkbox>
                <el-checkbox checked label="p014Sub">右眼柱镜值</el-checkbox>
                <el-checkbox checked label="p015">左眼柱镜</el-checkbox>
                <el-checkbox checked label="p015Sub">左眼柱镜值</el-checkbox>
                <el-checkbox checked label="p016">左眼轴向值</el-checkbox>
                <el-checkbox checked label="p017">右眼轴向值</el-checkbox>
                <el-checkbox checked label="p021">沙眼</el-checkbox>
                <el-checkbox checked label="p022">疑沙</el-checkbox>
                <el-checkbox checked label="p023">结膜炎</el-checkbox>
                <el-checkbox checked label="p024">色觉</el-checkbox>
                <el-checkbox checked label="p025">心率</el-checkbox>
                <el-checkbox checked label="p026">听力/右</el-checkbox>
                <el-checkbox checked label="p026Sub">听力/左</el-checkbox>
                <el-checkbox checked label="p027">鼻病</el-checkbox>
                <el-checkbox checked label="p028">口腔（龋）</el-checkbox>
                <el-checkbox checked label="ruya">乳牙</el-checkbox>
                <el-checkbox checked label="hengya">恒牙</el-checkbox>
                <el-checkbox checked label="p029">牙周炎</el-checkbox>
                <el-checkbox checked label="p033">蛔虫</el-checkbox>
                <el-checkbox checked label="p034">咽喉</el-checkbox>
                <el-checkbox checked label="p035">心</el-checkbox>
                <el-checkbox checked label="p036">肺</el-checkbox>
                <el-checkbox checked label="p037">脾</el-checkbox>
                <el-checkbox checked label="p038">肝</el-checkbox>
                <el-checkbox checked label="p039">脊柱</el-checkbox>
                <el-checkbox checked label="p040">四肢</el-checkbox>
                <el-checkbox checked label="p041">平足</el-checkbox>
                <el-checkbox checked label="p042">头部</el-checkbox>
                <el-checkbox checked label="p043">颈部</el-checkbox>
                <el-checkbox checked label="p044">胸部</el-checkbox>
                <el-checkbox checked label="p045">皮肤</el-checkbox>
                <el-checkbox checked label="p046">汗斑</el-checkbox>
                <el-checkbox checked label="p047">甲状腺</el-checkbox>
                <el-checkbox checked label="p048">扁桃体</el-checkbox>
                <el-checkbox checked label="p049">疥疮</el-checkbox>
                <el-checkbox checked label="p050">HBsAb</el-checkbox>
                <el-checkbox checked label="p051">血型</el-checkbox>
                <el-checkbox checked label="p052">血色(/L)</el-checkbox>
                <el-checkbox checked label="p053">丙谷转氨酶</el-checkbox>
                <el-checkbox checked label="p054">结核菌素</el-checkbox>
                <el-checkbox checked label="p055">胆红素</el-checkbox>
                <el-checkbox checked label="p056">淋巴结</el-checkbox>
                <el-checkbox checked label="p057">既往病史</el-checkbox>
                <el-checkbox checked label="p058">胸透</el-checkbox>
                <el-checkbox checked label="p059">脉搏</el-checkbox>
                <el-checkbox checked label="p060">睾丸</el-checkbox>
                <el-checkbox checked label="p067">坐位体前屈</el-checkbox>
                <el-checkbox checked label="p069">臀围</el-checkbox>
                <el-checkbox checked label="p071">眼外观</el-checkbox>
                <el-checkbox checked label="p072">外耳道与鼓膜</el-checkbox>
                <el-checkbox checked label="p073">听力体检</el-checkbox>
                <el-checkbox checked label="p074">嗅觉</el-checkbox>
                <el-checkbox checked label="result">结果</el-checkbox>
                <el-checkbox checked label="advice">建议</el-checkbox>
              </div>
            </el-checkbox-group>


            <el-button size="medium" slot="reference" v-show="$hasPermission('medicalInformation.filter')"
                       @click="filter"
                       style="margin-right: 10px">筛选
            </el-button>
          </el-popover>

          <el-button size="medium" v-show="$hasPermission('medicalInformation.print')&& $store.getters.inDate>=0" @click="printClick">打印
          </el-button>
          <!--          <el-button size="medium" v-show="$hasPermission('medicalInformation.preview')">预览</el-button>-->
          <!--          <el-button v-show="$hasPermission('medicalInformation.export')" @click="exportExcel">导出</el-button>-->
          <el-button size="medium" v-show="$hasPermission('medicalInformation.export')&& $store.getters.inDate>=0" @click="list2">导出</el-button>
          <el-button size="medium" v-show="$hasPermission('medicalInformation.import') && $store.getters.inDate>=0"
                     @click="importExcel">数据导入
          </el-button>
          <el-button size="medium" v-show="$hasPermission('medicalInformation.import') && $store.getters.inDate>=0"
                     @click="refreshResult">刷新结果
          </el-button>
        </div>
      </div>

      <el-table
          fit
          :key="tableKey"
          v-loading="loading"
          id="exportTab"
          ref="multipleTable"
          :data="formList"
          :header-cell-style="{background:'#e3e3e3'}"
          size="mini"
          row-key="id"
          @selection-change="handleSelectionChange"

      >
        <el-table-column
            align="center"
            header-align="center"
            label="全选"
            type="selection"
            v-if="showOpt"
            fixed

        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('schoolId')"
            label="学校"
            prop="schoolId"
            fixed
            width="150px"
        >
          <template slot-scope="scope">
            {{ scope.row.schoolVO.name | f1 }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"

            v-if="isShow('grade')"
            label="年级"
            prop="grade"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('classRoomNo')"
            label="班级"
            prop="classRoomNo"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('name')"
            label="姓名"
            prop="name"
            fixed>
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('sex')"
            label="性别"
            prop="sex">
          <template slot-scope="scope">
            <div>{{ menu.GenderEnum[scope.row.sex] }}</div>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('nationalityCode')"
            label="民族"
            prop="nationalityCode">
          <template slot-scope="scope">
            <div>{{ nationalityName(scope.row.nationalityCode) }}</div>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('studentId')"
            label="学号"
            width="150px"
            prop="studentId">
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('idNo')"
            width="200px"
            label="身份证号"
            prop="idNo">
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('birthDate')"
            label="出生年月日"
            width="150px"
            prop="birthDate">
          <template slot-scope="scope">
            <div>{{ $dateString(scope.row.birthDate) }}</div>
          </template>
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('contact')"
            label="联系电话"
            prop="contact">
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('checkDate')"
            label="检测日期"
            width="150x"
            prop="checkDate">
          <template slot-scope="scope">
            <div>{{ $dateString(scope.row.checkDate) }}</div>
          </template>

        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('year')"
            label="体检年度"
            prop="year">
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('age')"
            label="年龄"
            prop="age">
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('cxType')"
            label="城乡"
            prop="cxType">
          <template slot-scope="scope">
            <div>{{ menu.CityEnum[scope.row.cxType] }}</div>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p001')"
            label="营养状况"
            width="100px"

        >
          <template slot-scope="scope">
            {{$YYInfo(scope.row)}}
          </template>
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p002')"
            label="身高（cm）"
            width="100px"
            prop="p002"
        >

        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p003')"
            label="体重（kg）"
            width="100px"
            prop="p003"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p004')"
            width="100px"
            label="肺活量（ml）"
            prop="p004"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p005')"
            label="血压(mmHg)/收缩"
            width="100px"
            prop="p005"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p005Sub')"
            label="血压(mmHg)/舒张"
            width="100px"
            prop="p005Sub"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p006')"
            label="胸围（cm）"
            width="100px"
            prop="p006"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p007')"
            label="腰围（cm）"
            width="100px"
            prop="p007"
        >
        </el-table-column>

        <!--        <el-table-column-->
        align="center"
        header-align="center"
        <!--        v-if="isShow('p008')"-->
        <!--            label="头围（cm）"-->
        <!--            width="100px"-->
        <!--            prop="p008"-->
        <!--        >-->
        <!--        </el-table-column>-->

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p009')"
            label="视力/右"
            width="100px"
            prop="p009"
        >
        </el-table-column>


        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p009Sub')"
            label="视力/左"
            width="100px"
            prop="p009Sub"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p010')"
            label="视力新发病"
            width="100px"
            prop="p010"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p011')"
            label="矫正视力/右"
            width="100px"
            prop="p011"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p011Sub')"
            label="矫正视力/左"
            width="100px"
            prop="p011Sub"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p012')"
            label="右眼球镜"
            width="100px"
            prop="p012"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p012Sub')"
            label="右眼球镜值"
            width="100px"
            prop="p012Sub"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p013')"
            label="左眼球镜"
            width="100px"
            prop="p013"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p013Sub')"
            label="左眼球镜值"
            width="100px"
            prop="p013Sub"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p014')"
            label="右眼柱镜"
            width="100px"
            prop="p014"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p014Sub')"
            label="右眼柱镜值"
            width="100px"
            prop="p014Sub"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p015')"
            label="左眼柱镜"
            width="100px"
            prop="p015"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p015Sub')"
            label="左眼柱镜值"
            width="100px"
            prop="p015Sub"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p016')"
            label="左眼轴向值"
            width="100px"
            prop="p016"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p017')"
            label="右眼轴向值"
            width="100px"
            prop="p017"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p021')"
            label="沙眼"
            width="100px"
            prop="p021"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p022')"
            label="疑沙"
            width="100px"
            prop="p022"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p023')"
            label="结膜炎"
            width="100px"
            prop="p023"
        >
        </el-table-column>


        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p024')"
            label="色觉"
            width="100px"
            prop="p024"
        >
        </el-table-column>


        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p025')"
            label="心率"
            width="100px"
            prop="p025"
        >
        </el-table-column>


        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p026')"
            label="听力/右"
            width="100px"
            prop="p026"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('6Sub')"
            label="听力/左"
            width="100px"
            prop="p026Sub"
        >
        </el-table-column>


        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p027')"
            label="鼻病"
            width="100px"
            prop="p027"
        >
        </el-table-column>


        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p028')"
            label="口腔（龋齿）"
            width="100px"
            prop="p028"
        >
        </el-table-column>


        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p029')"
            label="牙周炎"
            width="100px"
            prop="p029"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p033')"
            label="蛔虫"
            width="100px"
            prop="p033"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p034')"
            label="咽喉"
            width="100px"
            prop="p034"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p035')"
            label="心"
            width="100px"
            prop="p035"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p036')"
            label="肺"
            width="100px"
            prop="p036"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p037')"
            label="脾"
            width="100px"
            prop="p037"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p038')"
            label="肝"
            width="100px"
            prop="p038"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p039')"
            label="脊柱"
            width="100px"
            prop="p039"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p040')"
            label="四肢"
            width="100px"
            prop="p040"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p041')"
            label="平足"
            width="100px"
            prop="p041"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p042')"
            label="头部"
            width="100px"
            prop="p042"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p043')"
            label="颈部"
            width="100px"
            prop="p043"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p044')"
            label="胸部"
            width="100px"
            prop="p044"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p045')"
            label="皮肤"
            width="100px"
            prop="p045"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p046')"
            label="汗斑"
            width="100px"
            prop="p046"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p047')"
            label="甲状腺"
            width="100px"
            prop="p047"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p048')"
            label="扁桃体"
            width="100px"
            prop="p048"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p049')"
            label="疥疮"
            width="100px"
            prop="p049"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p050')"
            label="HBsAb"
            width="100px"
            prop="p050"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p042')"
            label="头部"
            width="100px"
            prop="p042"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p051')"
            label="血型"
            width="100px"
            prop="p051"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p052')"
            label="血色素(g/L)"
            width="100px"
            prop="p052"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p053')"
            label="丙谷转氨酶"
            width="100px"
            prop="p053"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p054')"
            label="结核菌素"
            width="100px"
            prop="p054"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p055')"
            label="胆红素"
            width="100px"
            prop="p055"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p056')"
            label="淋巴结"
            width="100px"
            prop="p056"
        >
        </el-table-column>


        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p058')"
            label="胸透"
            width="100px"
            prop="p058"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p059')"
            label="脉搏"
            width="100px"
            prop="p059"
        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p060')"
            label="睾丸"
            width="100px"
            prop="p060"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p057')"
            label="既往病史"
            width="100px"
            prop="p057"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p067')"
            label="坐位体前屈"
            width="100px"
            prop="p067"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p069')"
            label="臀围"
            width="100px"
            prop="p069"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p071')"
            label="眼外观"
            width="100px"
            prop="p071"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p072')"
            label="外耳道与鼓膜"
            width="100px"
            prop="p072"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p073')"
            label="听力体检"
            width="100px"
            prop="p073"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('p074')"
            label="嗅觉"
            width="100px"
            prop="p074"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('result')"
            label="结果"
            prop="result"
            width="200px"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            v-if="isShow('advice')"
            label="建议"
            width="200px"
            prop="advice"

        >
        </el-table-column>

        <el-table-column
            align="center"
            header-align="center"
            width="250px"
            label="操作"
            v-if="showOpt"
            fixed="right"
        >
          <template slot-scope="scope" v-if="showOpt">
            <el-button v-show="$hasPermission('medicalInformation.list') && $store.getters.inDate>=0 " type="text"
                       @click="updateDialog(scope.row)">编辑
            </el-button>
            <el-button v-show="$hasPermission('medicalInformation.result')" type="text" @click="result(scope.row)">
              结果明细
            </el-button>
            <el-button v-show="$hasPermission('medicalInformation.check')" type="text"
                       @click="checkDialog(scope.row)">
              查看
            </el-button>
            <el-button v-show="$hasPermission('medicalInformation.tijianInfo')&& $store.getters.inDate>=0" type="text"
                       @click="toHealthForm(scope.row)">
              体检表
            </el-button>

          </template>
        </el-table-column>

      </el-table>


      <pagination :pageNo.sync="form.pageNo" :pageSize.sync="form.pageSize" :total.sync="total"></pagination>

    </el-card>
    <edit v-if="isEditShow" :row="current" :rowCurrent="updateCurrent"
          @close="()=>this.isEditShow = !this.isEditShow" @update="list"></edit>

    <result v-if="isResult" :row="current" :rowCurrent="updateCurrent"
            @close="()=>this.isResult = !this.isResult"></result>


    <importData v-if="isImportData" @close="()=>this.isImportData = !this.isImportData"></importData>
    <check v-if="showCheck" :row="checkList" @close="()=>this.showCheck = !showCheck"></check>

  </div>
</template>

<script>
import edit from "./edit"
import result from "./result";
import importData from './importData'
import pagination from "@/components/Pagination"
import SchoolSelect from "@/components/SchoolSelect";
import ZxxSchoolSelect from "@/components/ZxxSchoolSelect";
import DataSelect from "@/components/DataSelect"
import nationalitySelect from "@/components/nationalitySelect"
import yearSelect from "@/components/yearSelect"
import * as medicalInformationApi from "@/api/gen/medicalInformationApi"
import * as dataMenu from "@/assets/js/data"
import {TZInfoShow, YYInfoShow, SGInfoShow} from '@/plugin/dataInfo'
import {isEnabled, 未做} from "./util";
import * as organizationCheckItemStatusApi from "@/api/gen/organizationCheckItemStatusApi"

export default {
  components: {
    SchoolSelect,
    edit,
    result,
    pagination,
    importData,
    DataSelect,
    nationalitySelect,
    ZxxSchoolSelect,
    yearSelect
  },
  name: "physicalInfomation",
  data() {
    return {
      loading: false,
      tableKey: new Date().getTime(),
      showOpt: true,
      menu: dataMenu,
      form: {
        schoolId: "",
        name: '',
        sex: '',
        nationalityCode: '',
        grade: '',
        classRoomNo: '',
        checkDate: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      date: "",
      formList: [],
      isEditShow: false,
      isUpdateDialog: false,
      current: {},
      value: '',
      id: [],
      updateCurrent: {},

      isResult: false,
      isImportData: false,


      checkboxGroup: [],
      formList2: [],


      Gender: '',
      CityType: '',
      optionsList:[],

      fullscreenLoading: false,


      showCheck: false,
      checkList: "", //查看列表
    }
  },

  watch: {
    'form.pageNo':{
      handler(){
        this.list()
      }
    },
    'form.pageSize':{
      handler(){
        this.list()
      }
    }
  },

  async mounted() {

    this.CityType = await this.$baseData('CityType')
    this.Gender = await this.$baseData('Gender')

    if(this.optionsList.length==0){
        const res = (await organizationCheckItemStatusApi.list({
          organizationId: this.$store.getters.organizationId,
          pageSize: 1000002
        }));

        
        this.optionsList = this._.chain(res.data).filter(item => item.status==0).sortBy(x => parseFloat(x.sortKey)).value()
        var _temp =[];
        for(let i=0;i<this.checkboxGroup.length;i++){
          var one = this.checkboxGroup[i];
          if(this.optionsList.filter(it=> it.checkItemVO.code == one ||it.checkItemVO.subCode == one).length>0){
            continue
          }else{
            _temp.push(one)
          }
        }
        this.checkboxGroup = _temp
      }

  },
  computed: {

    GenderEnum() {
      return Object.values(this.menu.GenderEnum)
    },


  },
  methods: {
    TZInfoShow,
    YYInfoShow,
    SGInfoShow,
    addDialogShow() {
      this.toggleSelection()
      this.isEditShow = true
      this.current = {}
      this.current.status = 'add';
      this.updateCurrent = {}


    },

    updateDialog(data) {
        var copy = {};
      for (let key in data) {
        // if(data[key]&&data[key]!="未做"){
          copy[key] = data[key]
        // }
      }
      this.updateCurrent = ""
      this.updateCurrent = {...copy}
      this.isEditShow = true
    
    
      this.current =copy
      this.current.status = 'edit'


    },
    //查看
    checkDialog(data) {
      this.updateCurrent = ""
      this.updateCurrent = this.formList.filter(item => item.id === data.id).pop()
      this.isEditShow = true
      this.current = data
      this.current.status = 'check'

    },
    result(data) {
      for (let item of this.formList) {
        if (item.id === data.id) {
          this.updateCurrent = item
        }
      }
      this.isResult = true
      this.current = data
    },

    importExcel() {
      this.isImportData = true
    },


//获取列表
    async list() {
      if(!(this.$store.getters.isSuperAdmin || this.$store.getters.isAdminOrg)){
        if(!this.form.schoolId){
          this.$message.warning('请选择学校')
          this.loading = false
          return
        }
      }
      this.loading = true
      // const res = (await yEYMedicalInformationApi.list(this.form))
      // this.formList = res.data
      // this.total = res.count

      let clone = this._.cloneDeep(this.form)
      let allSchools = this.$refs.schools.getItems().map(x => x.id).join(',');


      clone.schoolId = this._.isEmpty(clone.schoolId) ? allSchools : clone.schoolId;


      if (!this._.isEmpty(clone.schoolId)) {
        let res = (await medicalInformationApi.list2(clone))
        res.data.forEach(item => {
          Object.keys(item).filter(x => x.startsWith('p0')).forEach(x => {
            item[x] = isEnabled(item, x) ? (item[x]) : 未做;
          })
        })
        this.formList = res.data
        this.total = res.count

      }

      this.loading = false
    },

//查找民族--名字
    nationalityName(code) {
      let arr = [];
      let obj;
      for (let item of this.$store.state.nationalityList) {
        obj = {
          code: item.code,
          name: item.name
        }
        arr.push(obj)
      }
      for (let item of arr) {
        if (item.code === code) {
          return item.name
        }
      }
    },

//获取选中的id
    handleSelectionChange(e) {
      this.current = e
      let arr = []
      for (let item of e) {
        arr.push(item.id)
      }
      this.id = arr
    },
//点击新增时取消选中项
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

//删除
    remove() {
      if (!this.id.length) {
        return this.$message.warning('请选择需要删除项')
      }

      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        distinguishCancelAndClose: true,
        center: true
      }).then(async () => {

        await medicalInformationApi.deleteByIds({ids: this.id})
        await this.list()
        this.$message.success("删除成功")
      }).catch((err) => {

      });


    },

    empty() {
      this.form = {}
      this.date = ''
      this.list()
    },


    dateChange(e) {
      if (!e?.length) {
        this.form.checkDate = ''
      } else {
        this.form.checkDate = e?.join(" - ")
      }

    },

    //导出
    exportExcel() {
      this.$exportToExcel(`${this.$store.getters.organizationName}-${this.form.year}-${this.$storage.getStorage('pageName')}-${this.$dateString(this.$now())}.xlsx`, '#exportTab')
    },

    //刷新结果
    async refreshResult() {
      await medicalInformationApi.genResult(this.form)
    },

     isShow(label) {
      
      // var re =  this.optionsList.filter(one=>one.checkItemVO.code==label||one.checkItemVO.subCode==label).length >0
      // console.log(label+":"+re);
      
      // return re;
      return this.checkboxGroup.includes(label);
    },


    //学校Name
    findSchoolName() {

      let items = this.$refs.schools.getItems()
      return items.filter(item => {
        if (item.id === this.form.schoolId) {
          return item
        }
      })[0].name

    },


    filter() {


    },
    async printClick() {
      this.showOpt = false;
      this.tableKey = new Date().getTime();
      this.$nextTick(() => {
        this.$print('#exportTab', {clearClass: true, clearStyle: true})
        this.showOpt = true;
        this.tableKey = new Date().getTime();

      })

    },
    async list2() {
      // if (this.total > 50000) {
      //   this.$message.warning('最多可导出5万条数据,请联系管理员')
      //   return;
      // }
      if(!this.$store.getters.isSuperAdmin){
        if(!this.form.schoolId){
          this.$message.warning('请选择学校')
          return
        }
      }


      const loading = this.$loading({
        lock: true,
        text: '导出中,请稍等...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });



      let header = [];

      if (this.isShow("schoolId")) {header.push("学校")}
      if (this.isShow("grade")) {header.push("年级")}
      if (this.isShow("classRoomNo")) {header.push("班级")}
      if (this.isShow("name")) {header.push("姓名")}
      if (this.isShow("sex")) {header.push("性别")}
      if (this.isShow("nationalityCode")) {header.push("民族")}
      if (this.isShow("studentId")) {header.push("学号")}
      if (this.isShow("idNo")) {header.push("身份证")}
      if (this.isShow("birthDate")) {header.push("出生年月日")}
      if (this.isShow("contact")) {header.push("联系电话")}
      if (this.isShow("checkDate")) {header.push("检测日期")}
      if (this.isShow("year")) {header.push("体检年度")}
      if (this.isShow("age")) {header.push("年龄")}
      if (this.isShow("cxType")) {header.push("城乡")}
      if (this.isShow("p001")) {header.push("营养状况")}
      if (this.isShow("p002")) {header.push("身高（cm）")}
      if (this.isShow('sgInfo')) {header.push("身高信息")}
      if (this.isShow("p003")) {header.push("体重（kg）")}
      if (this.isShow("tzInfo")) {header.push("体重信息")}
      if (this.isShow("p004")) {header.push("肺活量（ml）")}
      if (this.isShow("p005")) {header.push("血压mmHg/收缩")}
      if (this.isShow("p005Sub")) {header.push("血压mmHg/舒张")}
      if (this.isShow("p006")) {header.push("胸围（cm）")}
      if (this.isShow("p007")) {header.push("腰围（cm）")}
      if (this.isShow("p009")) {header.push("视力/右")}
      if (this.isShow("p009Sub")) {header.push("视力/左")}
      if (this.isShow("p010")) {header.push("视力新发病")}
      if (this.isShow("p011")) {header.push("矫正视力/右")}
      if (this.isShow("p011Sub")) {header.push("矫正视力/左")}
      if (this.isShow("p012")) {header.push("右眼球镜")}
      if (this.isShow("p012Sub")) {header.push("右眼球镜值")}
      if (this.isShow("p013")) {header.push("左眼球镜")}
      if (this.isShow("p013Sub")) {header.push("左眼球镜值")}
      if (this.isShow("p014")) {header.push("右眼柱镜")}
      if (this.isShow("p014Sub")) {header.push("右眼柱镜值")}
      if (this.isShow("p015")) {header.push("左眼柱镜")}
      if (this.isShow("p015Sub")) {header.push("左眼柱镜值")}
      if (this.isShow("p016")) {header.push("左眼轴向值")}
      if (this.isShow("p017")) {header.push("右眼轴向值")}
      if (this.isShow("p021")) {header.push("沙眼")}
      if (this.isShow("p022")) {header.push("疑沙")}
      if (this.isShow("p023")) {header.push("结膜炎")}
      if (this.isShow("p024")) {header.push("色觉")}
      if (this.isShow("p025")) {header.push("心率")}
      if (this.isShow("p026")) {header.push("听力/右")}
      if (this.isShow("p026Sub")) {header.push("听力/左")}
      if (this.isShow("p027")) {header.push("鼻病")}
      if (this.isShow("p028")) {header.push("口腔（龋）")}
      if (this.isShow("ruya")) {header.push("乳牙")}
      if (this.isShow("hengya")) {header.push("恒牙")}
      if (this.isShow("p029")) {header.push("牙周炎")}
      if (this.isShow("p033")) {header.push("蛔虫")}
      if (this.isShow("p034")) {header.push("咽喉")}
      if (this.isShow("p035")) {header.push("心")}
      if (this.isShow("p036")) {header.push("肺")}
      if (this.isShow("p037")) {header.push("脾")}
      if (this.isShow("p038")) {header.push("肝")}
      if (this.isShow("p039")) {header.push("脊柱")}
      if (this.isShow("p040")) {header.push("四肢")}
      if (this.isShow("p041")) {header.push("平足")}
      if (this.isShow("p042")) {header.push("头部")}
      if (this.isShow("p043")) {header.push("颈部")}
      if (this.isShow("p044")) {header.push("胸部")}
      if (this.isShow("p045")) {header.push("皮肤")}
      if (this.isShow("p046")) {header.push("汗斑")}
      if (this.isShow("p047")) {header.push("甲状腺")}
      if (this.isShow("p048")) {header.push("扁桃体")}
      if (this.isShow("p049")) {header.push("疥疮")}
      if (this.isShow("p050")) {header.push("HBsAb")}
      if (this.isShow("p051")) {header.push("血型")}
      if (this.isShow("p052")) {header.push("血色(/L)")}
      if (this.isShow("p053")) {header.push("丙谷转氨酶")}
      if (this.isShow("p054")) {header.push("结核菌素")}
      if (this.isShow("p055")) {header.push("胆红素")}
      if (this.isShow("p056")) {header.push("淋巴结")}
      if (this.isShow("p057")) {header.push("既往病史")}
      if (this.isShow("p058")) {header.push("胸透")}
      if (this.isShow("p059")) {header.push("脉搏")}
      if (this.isShow("p060")) {header.push("睾丸")}
      if (this.isShow("p067")) {header.push("坐位体前屈")}
      if (this.isShow("p069")) {header.push("臀围")}
      if (this.isShow("p071")) {header.push("眼外观")}
      if (this.isShow("p072")) {header.push("外耳道与鼓膜")}
      if (this.isShow("p073")) {header.push("听力体检")}
      if (this.isShow("p074")) {header.push("嗅觉")}
      if (this.isShow("result")) {header.push("结果")}
      if (this.isShow("advice")) {header.push("建议")}
      let workSheet = this.$XLSX.utils.aoa_to_sheet([header]);

      let pageNo = 1;
      let pageSize = 5000;

      while (true) {
        let clone = {...this.form};
        clone.pageNo = pageNo;
        clone.pageSize = pageSize;
        // if (this.$store.getters.isSuperAdmin) {
        //   clone.schoolId = ''
        // } else {
        //   if (!clone.schoolId) {
        //     clone.schoolId = this.$store.state.userInfo.user.organizationVO.schools.map(item => item).join(',')
        //   }
        //
        //   if (!clone.schoolId) {
        //     break;
        //   }
        // }

        clone.sortKey = 'grade,classRoomNo'
        // this.$message.success(pageNo.toString());

        console.log(clone)
        let resp = await medicalInformationApi.list(clone);


        let d = resp.data;


        if (!d || d.length === 0) {


          break;
        }

        let aoa = [];

        for (const data of d) {

          let row = [];

          if (this.isShow("schoolId")) row.push(data.schoolVO.name)
          if (this.isShow("grade")) row.push(data.grade)
          if (this.isShow("classRoomNo")) row.push(data.classRoomNo)
          if (this.isShow("name")) row.push(data.name)
          if (this.isShow("sex")) row.push(this.$findCode(this.Gender, data.sex))
          if (this.isShow("nationalityCode")) row.push(this.nationalityName(data.nationalityCode))
          if (this.isShow("studentId")) row.push(data.studentId)
          if (this.isShow("idNo")) row.push(data.idNo)
          if (this.isShow("birthDate")) row.push(this.$dateString(data.birthDate))
          if (this.isShow("contact")) row.push(data.contact)
          if (this.isShow("checkDate")) row.push(this.$dateString(data.checkDate))
          if (this.isShow("year")) row.push(data.year)
          if (this.isShow("age")) row.push(data.age)
          if (this.isShow("cxType")) row.push(this.$findCode(this.CityType, data.cxType))
          if (this.isShow("p001")) {this.YYInfoShow(row,data)}
          if (this.isShow("p002")) row.push(data.p002)
          if (this.isShow("sgInfo")) {this.SGInfoShow(row,data)}

          if (this.isShow("p003")) row.push(data.p003)
          if (this.isShow("tzInfo")) {this.TZInfoShow(row,data)}
          if (this.isShow("p004")) row.push((isEnabled(data, "p004") ? data.p004 : 未做))
          if (this.isShow("p005")) row.push((isEnabled(data, "p005") ? data.p005 : 未做))
          if (this.isShow("p005Sub")) row.push((isEnabled(data, "p005Sub") ? data.p005Sub : 未做))
          if (this.isShow("p006")) row.push((isEnabled(data, "p006") ? data.p006 : 未做))
          if (this.isShow("p007")) row.push((isEnabled(data, "p007") ? data.p007 : 未做))
          if (this.isShow("p009")) row.push((isEnabled(data, "p009") ? data.p009 : 未做))
          if (this.isShow("p009Sub")) row.push((isEnabled(data, "p009Sub") ? data.p009Sub : 未做))
          if (this.isShow("p010")) row.push((isEnabled(data, "p010") ? data.p010 : 未做))
          if (this.isShow("p011")) row.push((isEnabled(data, "p011") ? data.p011 : 未做))
          if (this.isShow("p011Sub")) row.push((isEnabled(data, "p011Sub") ? data.p011Sub : 未做))
          if (this.isShow("p012")) row.push((isEnabled(data, "p012") ? data.p012 : 未做))
          if (this.isShow("p012Sub")) row.push((isEnabled(data, "p012Sub") ? data.p012Sub : 未做))
          if (this.isShow("p013")) row.push((isEnabled(data, "p013") ? data.p013 : 未做))
          if (this.isShow("p013Sub")) row.push((isEnabled(data, "p013Sub") ? data.p013Sub : 未做))
          if (this.isShow("p014")) row.push((isEnabled(data, "p014") ? data.p014 : 未做))
          if (this.isShow("p014Sub")) row.push((isEnabled(data, "p014Sub") ? data.p014Sub : 未做))
          if (this.isShow("p015")) row.push((isEnabled(data, "p015") ? data.p015 : 未做))
          if (this.isShow("p015Sub")) row.push((isEnabled(data, "p015Sub") ? data.p015Sub : 未做))
          if (this.isShow("p016")) row.push((isEnabled(data, "p016") ? data.p016 : 未做))
          if (this.isShow("p017")) row.push((isEnabled(data, "p017") ? data.p017 : 未做))
          if (this.isShow("p021")) row.push((isEnabled(data, "p021") ? data.p021 : 未做))
          if (this.isShow("p022")) row.push((isEnabled(data, "p022") ? data.p022 : 未做))
          if (this.isShow("p023")) row.push((isEnabled(data, "p023") ? data.p023 : 未做))
          if (this.isShow("p024")) row.push((isEnabled(data, "p024") ? data.p024 : 未做))
          if (this.isShow("p025")) row.push((isEnabled(data, "p025") ? data.p025 : 未做))
          if (this.isShow("p026")) row.push((isEnabled(data, "p026") ? data.p026 : 未做))
          if (this.isShow("p026Sub")) row.push((isEnabled(data, "p026Sub") ? data.p026Sub : 未做))
          if (this.isShow("p027")) row.push((isEnabled(data, "p027") ? data.p027 : 未做))
          if (this.isShow("p028")) row.push((isEnabled(data, "p028") ? data.p028 : 未做))
          if (this.isShow("ruya")) row.push(this.findRY(data.p028))
          if (this.isShow("hengya")) row.push(this.findHY(data.p028))
          if (this.isShow("p029")) row.push((isEnabled(data, "p029") ? data.p029 : 未做))
          if (this.isShow("p033")) row.push((isEnabled(data, "p033") ? data.p033 : 未做))
          if (this.isShow("p034")) row.push((isEnabled(data, "p034") ? data.p034 : 未做))
          if (this.isShow("p035")) row.push((isEnabled(data, "p035") ? data.p035 : 未做))
          if (this.isShow("p036")) row.push((isEnabled(data, "p036") ? data.p036 : 未做))
          if (this.isShow("p037")) row.push((isEnabled(data, "p037") ? data.p037 : 未做))
          if (this.isShow("p038")) row.push((isEnabled(data, "p038") ? data.p038 : 未做))
          if (this.isShow("p039")) row.push((isEnabled(data, "p039") ? data.p039 : 未做))
          if (this.isShow("p040")) row.push((isEnabled(data, "p040") ? data.p040 : 未做))
          if (this.isShow("p041")) row.push((isEnabled(data, "p041") ? data.p041 : 未做))
          if (this.isShow("p042")) row.push((isEnabled(data, "p042") ? data.p042 : 未做))
          if (this.isShow("p043")) row.push((isEnabled(data, "p043") ? data.p043 : 未做))
          if (this.isShow("p044")) row.push((isEnabled(data, "p044") ? data.p044 : 未做))
          if (this.isShow("p045")) row.push((isEnabled(data, "p045") ? data.p045 : 未做))
          if (this.isShow("p046")) row.push((isEnabled(data, "p046") ? data.p046 : 未做))
          if (this.isShow("p047")) row.push((isEnabled(data, "p047") ? data.p047 : 未做))
          if (this.isShow("p048")) row.push((isEnabled(data, "p048") ? data.p048 : 未做))
          if (this.isShow("p049")) row.push((isEnabled(data, "p049") ? data.p049 : 未做))
          if (this.isShow("p050")) row.push((isEnabled(data, "p050") ? data.p050 : 未做))
          if (this.isShow("p051")) row.push((isEnabled(data, "p051") ? data.p051 : 未做))
          if (this.isShow("p052")) row.push((isEnabled(data, "p052") ? data.p052 : 未做))
          if (this.isShow("p053")) row.push((isEnabled(data, "p053") ? data.p053 : 未做))
          if (this.isShow("p054")) row.push((isEnabled(data, "p054") ? data.p054 : 未做))
          if (this.isShow("p055")) row.push((isEnabled(data, "p055") ? data.p055 : 未做))
          if (this.isShow("p056")) row.push((isEnabled(data, "p056") ? data.p056 : 未做))
          if (this.isShow("p057")) row.push((isEnabled(data, "p057") ? data.p057 : 未做))
          if (this.isShow("p058")) row.push((isEnabled(data, "p058") ? data.p058 : 未做))
          if (this.isShow("p059")) row.push((isEnabled(data, "p059") ? data.p059 : 未做))
          if (this.isShow("p060")) row.push((isEnabled(data, "p060") ? data.p060 : 未做))
          if (this.isShow("p067")) row.push((isEnabled(data, "p067") ? data.p067 : 未做))
          if (this.isShow("p069")) row.push((isEnabled(data, "p069") ? data.p069 : 未做))
          if (this.isShow("p071")) row.push((isEnabled(data, "p071") ? data.p071 : 未做))
          if (this.isShow("p072")) row.push((isEnabled(data, "p072") ? data.p072 : 未做))
          if (this.isShow("p073")) row.push((isEnabled(data, "p073") ? data.p073 : 未做))
          if (this.isShow("p074")) row.push((isEnabled(data, "p074") ? data.p074 : 未做))
          if (this.isShow("result")) row.push((data.result))
          if (this.isShow("advice")) row.push((data.advice))
          aoa.push(row);
        }


        this.$XLSX.utils.sheet_add_aoa(workSheet, aoa, {origin: -1});

        pageNo++;


      }


      let wb = this.$XLSX.utils.book_new();
      this.$XLSX.utils.book_append_sheet(wb, workSheet, "Sheet1");

      if (!this.form.schoolId) {
        this.$SaveWB(wb, "体检信息.xlsx");
      } else {
        this.$SaveWB(wb, this.findSchoolName() + "体检信息.xlsx");

      }


      loading.close()


    },
    toHealthForm(data) {
     let url = this.$router.resolve({path: '/ZxxHealthForm', query: {id: data.id}})
      window.open(url.href,"_blank")
    },
    toHealthFormBatch() {
      if (!this.current.length) {
        this.$message.warning('至少选择一项')
        return
      }

      let ids = this.current.map(item => {
        return item.id
      })

      let url = this.$router.resolve({path: '/ZxxHealthForm', query: {id: ids}})
      window.open(url.href,"_blank")

    },

    findHY(str) {
      if (!str) {
        return ""

      }

      let reg = /[DMF]\d+/g;
      let arr = [...str.matchAll(reg)]

      return arr.map(x => x[0]).join();
    },
    findRY(str) {
      if (!str) {
        return ""

      }
      let reg = /[dmf]\d+/g
      let arr = [...str.matchAll(reg)]

      return arr.map(x => x[0]).join();
    },


  }

}
</script>

<style scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;

}

/deep/ .el-table__row .cell {
  white-space: nowrap;
}
</style>
