import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import home from "@/views/home"
import announcement from "@/views/announcement"
import login from "@/views/login"
// import index from "@/views/index"
import index from "@/views/announcementInfomation"
import updatePassword from "@/views/updatePassword"
import user from "@/views/user"
import role from "@/views/role"
import unit from "@/views/unit"
import stamp from "@/views/stamp"
import logManagement from "@/views/logManagement"
import resultSuggest from "@/views/resultSuggest"
import projectSettings from "@/views/projectSettings"
import physicalInfomation from "@/views/physicalInfomation"
import physicalProject from "@/views/physicalProject"
import cariesSick from "@/views/cariesSick"
import permanent from "@/views/permanent"
import batch from "@/views/batch"
import eyesight from "@/views/eyesight"
import blood from "@/views/blood"
import lung from "@/views/lung"
import waistline from "@/views/waistline"
import shortSightedness from "@/views/shortSightedness"
import nutrition from "@/views/nutrition"
import otherDisease from "@/views/otherDisease"
import synthetical from "@/views/synthetical"
import commonDisease from "@/views/commonDisease"
import weightBase from "@/views/weightBase"
import statureBase from "@/views/statureBase"
import nutritionBase from "@/views/nutritionBase"
import superheavyBase from "@/views/superheavyBase"
import scriptManagement from "@/views/scriptManagement"
import cronJob from "@/views/cronJob"
import permission from "@/views/permission"
import schoolStatistic from "@/views/schoolStatistic"
import codeGen from "@/views/CodeGen/Index"
import fileManagement from "@/views/fileManagement"
import datahandler from "@/views/datahandler"
import dataDictionary from "@/views/dataDictionary"
import schoolManagement from "@/views/schoolManagement"
import zxxStudentData from '@/views/zxxStudentData'
import printTable from "@/components/printTable"
import ZxxHealthForm from "@/components/ZxxHealthForm"
import YeyHealthForm from "@/components/YeyHealthForm"
//体检汇总报告
import gatherHeight from "@/views/gather/gatherHeight/index.vue"
import gatherWeight from "@/views/gather/gatherWeight/index.vue"
import gatherWeightMark from "@/views/gather/gatherWeightMark/index.vue"
import gatherWaistLine from "@/views/gather/gatherWaistLine/index.vue"
import gatherLung from "@/views/gather/gatherLung/index.vue"
import gatherEyesight from "@/views/gather/gatherEyesight/index.vue"
import gatherCariesSick from "@/views/gather/gatherCariesSick/index.vue"
import gatherBlood from "@/views/gather/gatherBlood/index.vue"
import gatherBloodDiastolic from "@/views/gather/gatherBloodDiastolic/index.vue"
import gatherBloodSystolic from "@/views/gather/gatherBloodSystolic/index.vue"
import gatherTropia from "@/views/gather/gatherTropia/index.vue"
//幼儿园
import yeyProjectSettings from "@/views/yeyProjectSettings"
import yeyPhysicalInfomation from "@/views/yeyPhysicalInfomation"
import yeySchoolManagement from "@/views/yeySchoolManagement"
import yeyCariesSick from "@/views/yeyCariesSick"
import yeyPermanent from "@/views/yeyPermanent"
import yeyEyesight from "@/views/yeyEyesight"
import yeyShortSightedness from "@/views/yeyShortSightedness"
import yeyNutrition from "@/views/yeyNutrition"
import yeyCommonDisease from "@/views/yeyCommonDisease"
import yeyOtherDisease from "@/views/yeyOtherDisease"
import yeySynthetical from "@/views/yeySynthetical"
import yeyPhysicalProject from "@/views/yeyPhysicalProject"
import yeyNutritionBase from "@/views/yeyNutritionBase"
import yeyStatureBase from "@/views/yeyStatureBase"
import yeyWeightBase from "@/views/yeyWeightBase"


Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/home'
},
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '/updatePassword',
        name: 'updatePassword',
        component: updatePassword
    },
    {
        path: '/home',
        name: 'home',
        component: home,
        redirect: '/index',
        children: [
            {path: '/index', component: index, meta: {}},
            {path: '/batch', component: batch, meta: {}},
            // {path: '/announcementInfomation', component: announcementInfomation,meta: {permission: "announcementInfomation.list"}},
            {path: '/user', component: user, meta: {permission: "user.list"}},
            {path: '/role', component: role, meta: {permission: "role.list"}},
            {path: '/unit', component: unit, meta: {permission: "unit.list"}},
            {path: '/stamp', component: stamp, meta: {permission: "stamp.list"}},
            {path: '/projectSettings', component: projectSettings, meta: {permission: "projectsetting.list"}},
            {path: '/logManagement', component: logManagement, meta: {permission: "logManagement.list"}},
            {path: '/resultSuggest', component: resultSuggest, meta: {permission: "adviceresult.list"}},
            {path: '/physicalInfomation', component: physicalInfomation, meta: {permission: "medicalInformation.list"}},
            {
                path: '/physicalProject',
                component: physicalProject,
                meta: {permission: "organizationCheckItemStatus.list"}
            },
            {path: '/cariesSick', component: cariesSick, meta: {permission: "cariesSick.list"}},
            {path: '/permanent', component: permanent, meta: {permission: "permanent.list"}},
            {path: '/eyesight', component: eyesight, meta: {permission: "eyesight.list"}},
            {path: '/shortSightedness', component: shortSightedness, meta: {permission: "shortSightedness.list"}},
            {path: '/nutrition', component: nutrition, meta: {permission: "nutrition.list"}},
            {path: '/otherDisease', component: otherDisease, meta: {permission: "otherDisease.list"}},
            {path: '/synthetical', component: synthetical, meta: {permission: "synthetical.list"}},
            {path: '/blood', component: blood, meta: {permission: "blood.list"}},
            {path: '/lung', component: lung, meta: {permission: "lung.list"}},
            {path: '/waistline', component: waistline, meta: {permission: "waistline.list"}},
            {path: '/commonDisease', component: commonDisease, meta: {permission: "commonDisease.list"}},
            {path: '/weightBase', component: weightBase, meta: {permission: "weightBase.list"}},
            {path: '/statureBase', component: statureBase, meta: {permission: "statureBase.list"}},
            {path: '/nutritionBase', component: nutritionBase, meta: {permission: "nutritionBase.list"}},
            {path: '/scriptManagement', component: scriptManagement, meta: {permission: "scriptManagement.list"}},
            {path: '/cronJob', component: cronJob, meta: {permission: "cronJob.list"}},
            {path: '/permission', component: permission, meta: {permission: "permission.list"}},
            {path: '/schoolStatistic', component: schoolStatistic, meta: {permission: "schoolStatistic.list"}},
            {path: '/codeGen', component: codeGen, meta: {permission: "codeGen.list"}},
            {path: '/fileManagement', component: fileManagement, meta: {permission: "fileManagement.list"}},
            {path: '/dataDictionary', component: dataDictionary, meta: {permission: "dataDictionary.list"}},
            {path: '/schoolManagement', component: schoolManagement, meta: {permission: "schoolManagement.list"}},
            {path: '/announcement', component: announcement, meta: {permission: "announcement.list"}},
            {path: '/superheavyBase', component: superheavyBase, meta: {permission: "superheavyBase.list"}},
            {path: '/zxxStudentData', component: zxxStudentData, meta: {permission: "zxxStudentData.list"}},
            //体检汇总报告
            //|---身高评价等级
            {path: '/gatherHeight', component: gatherHeight, meta: {permission: "gatherHeight.list"}},
            //|---体重评价等级
            {path: '/gatherWeight', component: gatherWeight, meta: {permission: "gatherWeight.list"}},
            //|---体重指数等级
            {path: '/gatherWeightMark', component: gatherWeightMark, meta: {permission: "gatherWeightMark.list"}},
            //|---腰围等级
            {path: '/gatherWaistLine', component: gatherWaistLine, meta: {permission: "gatherWaistLine.list"}},
            //|---血压等级
            {path: '/gatherBlood', component: gatherBlood, meta: {permission: "gatherBlood.list"}},
            //|---舒张压评价等级报表
            {path: '/gatherBloodDiastolic', component: gatherBloodDiastolic, meta: {permission: "bloodDiastolic.list"}},
            //|---收缩压评价等级报表
            {path: '/gatherBloodSystolic', component: gatherBloodSystolic, meta: {permission: "bloodSystolic.list"}},
            //|---龋齿检出率
            {path: '/gatherCariesSick', component: gatherCariesSick, meta: {permission: "gatherCariesSick.list"}},
            //|---视力评价等级
            {path: '/gatherEyesight', component: gatherEyesight, meta: {permission: "gatherEyesight.list"}},
            //|---肺活量
            {path: '/gatherLung', component: gatherLung, meta: {permission: "gatherLung.list"}},
            //|---33cm眼位统计
            {path: '/gatherTropia', component: gatherTropia, meta: {permission: "gatherTropia.list"}},


            //幼儿园
            {
                path: '/yeyPhysicalInfomation',
                component: yeyPhysicalInfomation,
                meta: {permission: "yeyPhysicalInfomation.list"}
            },
            {path: '/yeyProjectSettings', component: yeyProjectSettings, meta: {permission: "yeyProjectSettings.list"}},
            {
                path: '/yeySchoolManagement',
                component: yeySchoolManagement,
                meta: {permission: "yeySchoolManagement.list"}
            },
            {path: '/yeyPhysicalProject', component: yeyPhysicalProject, meta: {permission: "yeyPhysicalProject.list"}},
            {path: '/yeyCariesSick', component: yeyCariesSick, meta: {permission: "yeyCariesSick.list"}},
            {path: '/yeyPermanent', component: yeyPermanent, meta: {permission: "yeyPermanent.list"}},
            {path: '/yeyEyesight', component: yeyEyesight, meta: {permission: "yeyEyesight.list"}},
            {
                path: '/yeyShortSightedness',
                component: yeyShortSightedness,
                meta: {permission: "yeyShortSightedness.list"}
            },
            {path: '/datahandler', component: datahandler, meta: {permission: "datahandler.handler"}},
            {path: '/yeyCommonDisease', component: yeyCommonDisease, meta: {permission: "yeyCommonDisease.list"}},
            {path: '/yeyNutrition', component: yeyNutrition, meta: {permission: "yeyNutrition.list"}},
            {path: '/yeyOtherDisease', component: yeyOtherDisease, meta: {permission: "yeyOtherDisease.list"}},
            {path: '/yeySynthetical', component: yeySynthetical, meta: {permission: "yeySynthetical.list"}},
            {path: '/yeyNutritionBase', component: yeyNutritionBase, meta: {permission: "yeyNutritionBase.list"}},
            {path: '/yeyStatureBase', component: yeyStatureBase, meta: {permission: "yeyStatureBase.list"}},
            {path: '/yeyWeightBase', component: yeyWeightBase, meta: {permission: "yeyWeightBase.list"}},
        ]
    },
    {path: '/printTable', component: printTable},
    {path: '/ZxxHealthForm', component: ZxxHealthForm},
    {path: '/YeyHealthForm', component: YeyHealthForm},

]

const router = new VueRouter({
    routes,
})

router.beforeEach(((to, from, next) => {
    console.log(to)
    let valid = store.getters.isLogin
    console.log(valid)
    if (to.path === '/login') {
        if (valid) {
            next('/home')
            return
        } else {
            next()
            return
        }
    }
    if (!valid) {
        next('/login')
        return
    }

    if (to.meta.permission) {
        if (!store.getters.hasPermission(to.meta.permission)) {
            next('/home')
            return
        }
    }


    next()


}))


export default router


