<template>
  <div>
    <gatherBasis :cells="cells" :type="type">
      <template #column>
        <el-table-column align="center" header-align="center" label="检查人数" prop="sum" width="150px"/>
        <el-table-column v-for="item in cells" :label="item.name" align="center" header-align="center">
          <el-table-column :prop="item.prop" align="center" header-align="center" label="人数"/>
          <el-table-column :prop="item.prop+'Ratio'" align="center" header-align="center" label="百分比"/>
        </el-table-column>
      </template>
    </gatherBasis>
  </div>

</template>
<script>
import gatherBasis from "../components/gatherBasis"
/*身高评价*/
export default {
  name: "gatherHeight",
  components: {
    gatherBasis
  },
  data() {
    return {
      type: "height",
      cells: [
        {prop: "r045", name: '上等'},
        {prop: "r043", name: '中等'},
        {prop: "r041", name: '下等'}]
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
<style scoped>

</style>