<template>
  <div>
    <gatherBasis :cells="cells" :type="type">
      <template #column>
        <el-table-column align="center" header-align="center" label="检查人数" prop="sum" width="150px"/>
        <el-table-column v-for="item in cells" :label="item.name" align="center" header-align="center">
          <el-table-column :prop="item.prop" align="center" header-align="center" label="人数"/>
          <el-table-column :prop="item.prop+'Ratio'" align="center" header-align="center" label="百分比"/>
        </el-table-column>
      </template>
    </gatherBasis>
  </div>

</template>
<script>
import gatherBasis from "../components/gatherBasis.vue"
/*腰围*/
export default {
  name: "gatherWaistLine",
  components: {
    gatherBasis
  },
  data() {
    return {
      type: "waistLine",
      cells: [
        {prop: "r097_0", name: '正常'},
        {prop: "r097_1", name: '腹型肥胖'}
      ]
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
<style scoped>

</style>