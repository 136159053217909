import request from '@/utils/request'


/**
 * 获取收缩压与舒张压的参考值
 * @param data
 * @returns {*}
 */
export function getMedicalInformationBloodRefer(data) {
    return request({
        url: '/medicalInformation/getMedicalInformationBloodRefer',
        method: 'POST',
        data
    })
}


export function create(data) {

    return request({
        url: '/medicalInformation/create',
        method: 'POST',
        data
    })
}


export function createBatch(data) {

    return request({
        url: '/medicalInformation/create/batch',
        method: 'POST',
        data
    })
}


export function deleteById(data) {

    return request({
        url: '/medicalInformation/deleteById',
        method: 'POST',
        data
    })
}


export function deleteByIds(data) {

    return request({
        url: '/medicalInformation/deleteByIds',
        method: 'POST',
        data
    })
}


export function genResult(data) {

    return request({
        url: '/medicalInformation/genResult',
        method: 'POST',
        data
    })
}


export function getById(params) {

    return request({
        url: '/medicalInformation/getById',
        method: 'GET',
        params
    })
}


export function getByIds(params) {

    return request({
        url: '/medicalInformation/getByIds',
        method: 'GET',
        params
    })
}

export function getByIds2(params) {
    if (!params) {
        params = {}
    }
    return request({
        url: '/medicalInformation/getByIds2',
        method: 'POST',
        data: params
    })
}


export function getResultAndAdvice(data) {

    return request({
        url: '/medicalInformation/getResultAndAdvice',
        method: 'POST',
        data
    })
}


export function importFromExcel(data) {

    return request({
        url: '/medicalInformation/importFromExcel',
        method: 'POST',
        data
    })
}


export function importFromExcel2(data) {

    return request({
        url: '/medicalInformation/importFromExcel2',
        method: 'POST',
        data
    })
}


export function list(params) {

    return request({
        url: '/medicalInformation/list',
        method: 'GET',
        params
    })
}


export function list2(data) {

    return request({
        url: '/medicalInformation/list2',
        method: 'POST',
        data
    })
}


export function update(data) {

    return request({
        url: '/medicalInformation/update',
        method: 'POST',
        data
    })
}


export function updateBatch(data) {

    return request({
        url: '/medicalInformation/update/batch',
        method: 'POST',
        data
    })
}


export function baseDataZWTQQList(params) {

    return request({
        url: '/baseDataZWTQQ/list',
        method: 'GET',
        params
    })
}
