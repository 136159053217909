import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import router from "@/router"
import {dfs2} from "@/plugin/tree";
import {diff, format, toDateTime} from "@/plugin/dateTime"


Vue.use(Vuex)


const store = new Vuex.Store({
    state: {
        token: null,
        userInfo: {},
        adminUserInfo: {},
        adminToken: null,
        formKey: new Date().getTime().toString(),
        menu: [{
            path: '/index', name: '首页', icon: 'iconfont icon-shouye'
            // {path: '/announcementInfomation', name: '公告信息'}
        },

            {
                path: '2-1',
                name: '系统管理',
                icon: 'iconfont icon-icon-test',
                children: [{path: "/user", name: "用户管理"}, {
                    path: "/role", name: "角色管理"
                }, // {path: "1-4-1", name: "数据备份"},
                    {path: "/unit", name: "单位管理"}, // {path: "1-4-1", name: "修改报表"},
                    {path: "/projectSettings", name: "项目设置"}, {
                        path: "/announcement", name: "公告管理"
                    }, {path: "/logManagement", name: "日志管理"}, {path: "/stamp", name: "公章管理"},


                    {
                        path: "2-1-1", name: "基础数据", children: [{
                            path: "2-1-1-1",
                            name: "中小学基础数据",
                            children: [{path: "/nutritionBase", name: "营养不良界值范围"}, {
                                path: "/statureBase", name: "身高发育等级评价"
                            }, {path: "/weightBase", name: "体重发育等级评价"}, {
                                path: "/superheavyBase", name: "性别年龄别 BMI 筛查超重与肥胖界值"
                            },]
                        }, {
                            path: "2-1-1-2", name: "幼儿园基础数据", children: [{
                                path: "/yeyNutritionBase", name: "龄别体重．身（长）高标准"
                            }, {path: "/yeyStatureBase", name: "身长（身高）别体重标准"}, {
                                path: "/yeyWeightBase", name: "性别年龄别 BMI 筛查超重与肥胖界值"
                            },]
                        },

                            {path: "/resultSuggest", name: "结果建议"}]
                    },]
            }, {
                path: '3-1',
                name: '中小学体检管理',
                icon: 'iconfont icon--tijianguanli',
                children: [{path: "/schoolManagement", name: "学校管理"}, {
                    path: "/physicalInfomation", name: "体检信息"
                }, {path: "/physicalProject", name: "项目设置"}, {path: "/zxxStudentData", name: "学生数据管理"},

                ]
            }, {
                path: '4-1', name: '中小学报表统计', icon: 'iconfont icon-report', children: [{
                    path: "/4-1-1", name: "龋齿患病情况", children: [{path: "/cariesSick", name: "龋齿患病情况统计"}, {
                        path: "/permanent", name: "恒牙龋失补构成比统计"
                    },]
                }, {
                    path: "/4-1-2", name: "视力统计情况", children: [{path: "/eyesight", name: "视力不良统计"}, {
                        path: "/shortSightedness", name: "近视情况统计"
                    },]
                }, {
                    path: "/4-1-3", name: "营养状况统计", children: [{path: "/nutrition", name: "营养状况统计"},]
                }, {
                    path: "/4-1-4", name: "常见病情况汇总", children: [{path: "/commonDisease", name: "常见病汇总"}, {
                        path: "/otherDisease", name: "其他常见病检出情况"
                    }, {path: "/blood", name: "血压统计"}, {path: "/lung", name: "肺活量统计"}, {
                        path: "/waistline", name: "腰围统计"
                    },]
                }, {
                    path: "/4-1-5",
                    name: "体检汇总报告",
                    children: [{path: "/gatherHeight", name: "身高评价等级报表"}, {
                        path: "/gatherWeight", name: "体重评价等级报表"
                    }, {
                        path: "/gatherWeightMark", name: "体重指数评价等级报表"
                    }, {
                        path: "/gatherWaistLine", name: "腰围评价等级报表"
                    }, {
                        path: "/gatherBlood", name: "血压评价等级报表"
                    }, {
                        path: "/gatherBloodSystolic", name: "收缩压评价等级报表"
                    }, {
                        path: "/gatherBloodDiastolic", name: "舒张压评价等级报表"
                    }, {
                        path: "/gatherCariesSick", name: "龋齿检出率报表"
                    }, {
                        path: "/gatherEyesight", name: "视力评价等级报表"
                    }, {
                        path: "/gatherLung", name: "肺活量评价等级报表"
                    }, {
                        path: "/gatherTropia", name: "33cm眼位统计报表"
                    }]
                }, {path: "/synthetical", name: "综合年报表"}

                ]
            }, {
                path: '5-1',
                name: '幼儿园体检管理',
                icon: 'iconfont icon--tijianguanli',
                children: [{path: "/yeySchoolManagement", name: "学校管理"}, {
                    path: "/yeyPhysicalInfomation", name: "体检信息"
                }, {path: "/yeyPhysicalProject", name: "项目设置"},

                ]
            }, {
                path: '6-1', name: '幼儿园报表统计', icon: 'iconfont icon-report', children: [{
                    path: "/4-1-1",
                    name: "龋齿患病情况",
                    children: [{path: "/yeyCariesSick", name: "龋齿患病情况统计"}, {
                        path: "/yeyPermanent", name: "乳牙龋失补构成比统计"
                    },]
                }, {
                    path: "/4-1-2", name: "视力统计情况", children: [{
                        path: "/yeyEyesight", name: "视力不良统计"
                    }, // {path: "/yeyShortSightedness", name: "近视情况统计"},
                    ]
                }, {
                    path: "/4-1-3", name: "营养状况统计", children: [{path: "/yeyNutrition", name: "营养状况统计"},]
                }, {
                    path: "/4-1-4",
                    name: "常见病情况汇总",
                    children: [{path: "/yeyCommonDisease", name: "常见病汇总"}, {
                        path: "/yeyOtherDisease", name: "其他常见病检出情况"
                    },]
                }, {path: "/yeySynthetical", name: "综合年报表"}

                ]
            }, {
                path: '7-1',
                name: '系统运维',
                icon: 'iconfont icon--tijianguanli',
                children: [{path: "/permission", name: "权限管理"}, {
                    path: "/schoolStatistic", name: "首页统计管理"
                }, {path: "/fileManagement", name: "文件管理"}, {
                    path: "/dataDictionary", name: "数据字典"
                },

                    {path: "/scriptManagement", name: "脚本管理"}, {path: "/datahandler", name: "数据管理"}, {
                        path: "/cronJob", name: "定时任务"
                    }, {path: "/codeGen", name: "代码生成"},

                ]
            },

        ],
        nationalityList: [],
        authStartTime: '',
        authEndTime: '',
        tableName: '',
        tableYear: '',
        tableSchoolName: ''

    }, mutations: {
        login(state, data) {
            state.userInfo = data
            state.token = data.token

            state.authStartTime = data.user.organizationVO.authStartTime
            state.authEndTime = data.user.organizationVO.authEndTime
        }, updateFromKey(state) {
            state.formKey = new Date().getTime().toString();
        },


        switchUser(state, data) {
            if (_.isEmpty(state.adminUserInfo) && _.isEmpty(state.adminToken)) {

                state.adminUserInfo = state.userInfo;
                state.adminToken = state.token;
            }

            state.userInfo = data
            state.token = data.token
            state.authStartTime = data.user.organizationVO.authStartTime
            state.authEndTime = data.user.organizationVO.authEndTime


        },

        exitSwitch(state) {
            state.userInfo = state.adminUserInfo
            state.token = state.adminToken
            state.authStartTime = state.adminUserInfo.user.organizationVO.authStartTime
            state.authEndTime = state.adminUserInfo.user.organizationVO.authEndTime
            state.adminUserInfo = {};
            state.adminToken = "";
        },

        logout(state) {
            state.token = "";
            state.userInfo = {}
            state.nationalityList = []
            state.authStartTime = ""
            state.authEndTime = ""
            state.organizationInfo = {}

            //删除本地存储
            localStorage.removeItem("vuex");
            localStorage.removeItem("__organizationList");
            localStorage.removeItem("__organizationCheckItemStatus");

        },


        setNationalityList(state, item) {
            state.nationalityList = item
        }, setTableName(state, item) {
            state.tableName = item
        }, setTableYear(state, item) {
            state.tableYear = item
        }, setTableSchoolName(state, item) {
            state.tableSchoolName = item
        }

    }, getters: {
        isLogin: state => {
            console.log(state);
            return !!state.userInfo.token
        }, userId: state => {
            return state.userInfo.user.id
        }, organizationId: state => {
            return state.userInfo.user.organizationId
        }, organizationName: state => {
            return state.userInfo.user.organizationVO.name
        }, organizationCode: state => {
            return state.userInfo.user.organizationVO.code
        }, organizationType: state => {
            return state.userInfo.user.organizationVO.type
        },

        username: state => {
            return state.userInfo.user.username
        }, roleIds: state => {
            return state.userInfo.user.roleIds
        }, roleName: state => {
            return state.userInfo.user.roles[0].name
        },

        schools: state => {
            return state.userInfo.user.organizationVO.schools
        }, dataPermission: state => {
            return state.userInfo?.user?.dataPermissions
        }, hasPermission(state, getters) {

            return (toCheck) => {

                return getters.isSuperAdmin || _.chain(getters.dataPermission).includes(toCheck).value() || _.chain(getters.permissions).includes(toCheck).value();
            }
        }, hasRole(state, getters) {

            return (toCheck) => {

                return getters.isSuperAdmin || _.chain(state.userInfo.user.roles).map(x => x.code).includes(toCheck).value();
            }
        }, isSuperAdmin(state) {

            let roles = state.userInfo?.user?.roles;

            if (!roles) {
                return false;
            }

            return roles.map(item => item.code).includes("super-admin")

        }, isAdminOrg(state) {

            let code = state.userInfo.user.organizationVO.code


            return code === 'adminOrg'

        },

        permissions(state, getters) {
            return _.chain([...state.userInfo?.user?.permissions] || []).value()
        },

        permissionMenus(state, getters) {

            let menu = _.cloneDeep(state.menu);

            let tmp = dfs2(menu, (n) => {

                let resolve = router.resolve({path: n.path});

                let p = resolve?.route?.meta?.permission

                if (!p || getters.hasPermission(p)) {


                    return n;

                } else {
                    return null;
                }


            })

            for (let i = 0; i < 10; i++) {

                tmp = dfs2(tmp, (n) => (n.children && n.children.length === 0) ? null : n);
            }

            return tmp;
        },


        inDate(state) {
            let now = format(toDateTime(new Date().getTime()), "yyyy-MM-dd HH:mm:ss")


            if (!state.authStartTime && !state.authEndTime) {
                return 0
            }
            return diff(now, state.authEndTime).days.toFixed(0)
        },


        excelOrgNameYear(state) {
            return `${state.tableName}${state.tableYear}`
        }

    },

    modules: {}, plugins: [createPersistedState()]
})

export default store
