<template>
  <div>
    <gatherBasis :cells="cells" :type="type">
      <template #column>
        <el-table-column align="center" header-align="center" label="检查人数" prop="sum" width="150px"/>
        <el-table-column v-for="item in cells" :label="item.name" align="center" header-align="center">
          <el-table-column :prop="item.prop" align="center" header-align="center" label="人数"/>
          <el-table-column :prop="item.prop+'Ratio'" align="center" header-align="center" label="百分比"/>
        </el-table-column>
      </template>
    </gatherBasis>
  </div>

</template>
<script>
import gatherBasis from "../components/gatherBasis.vue"
/*体重指标*/
export default {
  name: "gatherWeightMark",
  components: {
    gatherBasis
  },
  data() {
    return {
      type: "weightMark",
      cells: [
        {prop: "r037", name: '轻度低体重'},
        {prop: "r038", name: '中度低体重'},
        {prop: "r040", name: '低体重'},
        {prop: "r039", name: '重度低体重'},
        {prop: "r004", name: '超重'},
        {prop: "r005", name: '肥胖'},
      ]
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
<style scoped>

</style>