<template>
  <div>
    <el-card style=" margin: 20px 20px">

      <div style="margin-bottom: 20px">
        <el-button type="primary" @click="sync">数据同步</el-button>
        <el-button type="primary" @click="defaultSync">恢复默认</el-button>
      </div>
      <el-table
          :data="list"
          :header-cell-style="{background:'#eee'}"
      >
        <el-table-column
            label="项目名称"
            prop="name"
        >
          <template slot-scope="scope">
            {{ scope.row.checkItemVO.name }}
          </template>
        </el-table-column>

        <el-table-column
            label="适用类型"
            prop="types">
          <template slot-scope="scope">
            <span v-for="item in scope.row.checkItemVO.types" style="margin-right: 10px">{{ menu.SchoolTypeEnum[item] }}</span>
          </template>

        </el-table-column>
        <el-table-column
            label="排序"
        >
          <template slot-scope="scope">
            <el-input-number
                :key="scope.row.id"
                v-model="scope.row.sortKey"
                @change="sortKeyChange(scope.row)"
                size="small"
                :min="1"
                :max="10000"
                controls-position="right"
            >
            </el-input-number>
          </template>
        </el-table-column>


        <el-table-column
            align="right"
            label="操作"
        >
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       v-show="$hasPermission('organizationCheckItemStatus.update') && $store.getters.inDate>=0"
                       :active-value="1"
                       :inactive-value="0"
                       active-color="#13ce66"
                       inactive-color="#ff4949"
                       @change="change(scope.row,$event)">
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import * as organizationCheckItemStatusApi from "@/api/gen/organizationCheckItemStatusApi"
import pagination from "@/components/Pagination"
import * as dataMenu from "@/assets/js/data"


export default {
  components: {
    pagination
  },
  name: "physicalProject",
  data() {
    return {
      checkItem: [],
      current: {},
      menu: dataMenu,
      form: {
        name: "",
        pageNo: 1,
        pageSize: 500,
        organizationId: this.$store.getters.organizationId

      },
      total: 0,
      list: [],
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.handList()
      }
    }
  },
  async created() {
    await this.getList()
    this.handList = this._.debounce(this.getList, 600, false)
  },
  async mounted() {

  },

  computed: {},

  methods: {
    async getList() {
      const res = (await organizationCheckItemStatusApi.list(this.form))
      this.list = res.data.forEach(item => item.status)
      this.list = this._.chain(res.data).filter(item => item.checkItemVO.types.includes(1)).sortBy(x => parseFloat(x.sortKey)).value()
      this.total = res.count

    },
    async sync() {
      (await organizationCheckItemStatusApi.sync({id: this.form.organizationId}))

    },

    async change(data, e) {

      let params = {
        id: data.id,
        status: e,
        checkItemId: data.checkItemId
      }
      await organizationCheckItemStatusApi.update(params)
      localStorage.removeItem("__organizationCheckItemStatus");

    },


    async sortKeyChange(row) {
      await organizationCheckItemStatusApi.update({id: row.id, sortKey: row.sortKey})
      this.getList()
    },
    async defaultSync() {

      const res = (await organizationCheckItemStatusApi.list(this.form));


      await organizationCheckItemStatusApi.deleteByIds({ids: res.data.map(x => x.id)})
      this.$message.success("清除旧数据成功")
      await this.sync();
      this.$message.success("导入新数据成功")
      await this.getList();

    }
  }
}
</script>

<style scoped>

/deep/ .el-table td {
  padding: 12px 35px 12px 0 !important;
}

/deep/ .el-table th {
  padding: 12px 30px 12px 0 !important;
}

</style>