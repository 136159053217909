<template>
  <div v-show="config"
       style="display:flex; justify-content: space-between">

    <template v-if="config.inputType === 0">
      <div :class="{active:config.status===1}"
           style="white-space: nowrap; width: 8em; text-align: right;padding-right: 20px; flex-basis: 8em;flex-shrink: 0;">

        {{ config.name }}
      </div>
      <div class="width"
           style="display: flex;justify-content: space-between; width: 100%; flex: 1;">
        <div v-if="!$attrs.readOnly" :class="config.status === 1? 'two': ''" class="two">
          <el-tooltip :content="config.tips" :disabled="config.tips === ''" class="item" effect="dark"
                      placement="bottom">
            <el-input v-model.trim="p"></el-input>
          </el-tooltip>
        </div>

        <div v-else>{{ p }}</div>

        <div v-if="config.hasSub === 1" :class="{active:config.status===1}" style="flex: 1;">
          {{ config.subItemDescription }}
        </div>

        <div v-if="config.hasSub === 1" class="two">

          <template v-if="!$attrs.readOnly">
            <template v-if="config.subItemInputType === 0">
              <div style="display: flex; justify-content: space-between">

                <div class="one" style="flex: 2">
                  <el-tooltip :content="config.subItemTips" :disabled="config.subItemTips === ''" class="item"
                              effect="dark"
                              placement="bottom">
                    <el-input v-model.trim="pSub"></el-input>
                  </el-tooltip>
                </div>
              </div>
            </template>
            <template v-if="config.subItemInputType === 1">
              <div style="display: flex; justify-content: center">

                <el-select v-model.trim="pSub" style="flex: 2">
                  <el-option
                      v-for="(item,index) in config.subItemOptions"
                      :key="index"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>

            </template>
            <template v-if="config.subItemInputType === 2">

              <div style="display: flex; justify-content: center">

                <el-input v-model.trim="pSub" class="two"></el-input>
              </div>
            </template>
          </template>
          <div v-else>{{ pSub }}</div>
        </div>
      </div>

    </template>

    <template v-if="config.inputType === 1">
      <div :class="{active:config.status===1}"
           style="white-space: nowrap; width: 8em;text-align: right; padding-right: 20px;  flex-basis: 8em;flex-shrink: 0;">
        {{ config.name }}
      </div>

      <div
          style="display: flex; justify-content: space-between; flex: 1">
        <div v-if="!$attrs.readOnly" class="two">

          <el-select :id="config.code" v-model.trim="p">
            <el-option
                v-for="(item,index) in config.options"
                :key="index"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </div>

        <div v-else>{{ p }}</div>

        <div v-if="config.hasSub === 1" :class="{active:config.status===1,none:config.subItemDefaultOption === ' '}"
             style="flex: 1">{{
            config.subItemDescription
          }}
        </div>
        <div v-if="config.hasSub === 1" class="two">
          <template v-if="!$attrs.readOnly">
            <template v-if="config.subItemInputType === 0">
              <div style="display: flex; justify-content: space-between">

                <el-tooltip :content="config.subItemTips" :disabled="config.subItemTips === ''" class="item"
                            effect="dark"
                            placement="bottom">
                  <el-input v-model.trim="pSub"></el-input>
                </el-tooltip>

              </div>
            </template>
            <template v-if="config.subItemInputType === 1">
              <div style="display: flex; justify-content: center">
                <el-select v-model.trim="pSub" class="two" placeholder="请选择">
                  <el-option
                      v-for="(item,index) in config.subItemOptions"
                      :key="index"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>

            </template>
            <template v-if="config.subItemInputType === 2">

              <div style="display: flex; justify-content: center">
                <el-input v-model.trim="pSub" class="tow"></el-input>

              </div>
            </template>
          </template>
          <template v-else>
            <div>{{ pSub }}</div>
          </template>
        </div>


      </div>

    </template>

    <template v-if="config.inputType === 2">
      <div :class="{active:config.status===1}"
           style="white-space: nowrap; width: 8em;text-align: right; padding-right: 20px;  flex-basis: 8em;flex-shrink: 0;">
        {{ config.name }}
      </div>

      <div class="width" style="display: flex; justify-content: space-between; width: 100%;">
        <div style="width: 100%; display: flex">
          <div :class="config.hasSub === 1? 'two' : ''" class="one">
            <el-input v-model.trim="p" disabled/>
          </div>
          <div v-if="config.hasSub === 1">

            <template v-if="config.subItemInputType === 0">
              <div
                  style="display: flex; justify-content: space-between">
                <span class="one">{{ config.subItemDescription }}</span>
                <div class="two">
                  <el-tooltip :content="config.subItemTips" :disabled="config.subItemTips === ''" class="item"
                              effect="dark"
                              placement="bottom">
                    <el-input v-model.trim="pSub"></el-input>
                  </el-tooltip>
                </div>
              </div>
            </template>
            <template v-if="config.subItemInputType === 1">
              <div style="display: flex; justify-content: center">
                <span class="one">{{ config.subItemDescription }}</span>
                <el-select v-model.trim="pSub" class="two" placeholder="请选择">
                  <el-option
                      v-for="(item,index) in config.subItemOptions"
                      :key="index"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>

            </template>
            <template v-if="config.subItemInputType === 2">

              <div style="display: flex; justify-content: center">
                <span class="one">{{ config.subItemDescription }}</span>
                <el-input v-model.trim="pSub" class="two"></el-input>

              </div>
            </template>
          </div>
        </div>
      </div>

    </template>


  </div>
</template>

<script>
import SetDefaultValue from '@/mixins/SetDefaultValue'

export default {
  name: "CheckItems",
  mixins: [SetDefaultValue],
  props: {
    config: {},
    row: {},
  },
  beforeCreate() {
    this.st = {}
    this.st.beforeCreate = new Date().getTime();
  },
  beforeUpdate() {
    // console.log("beforeUpdate " + this.config.code, "");
  },
  created() {
    this.st.created = new Date().getTime();
  },
  mounted() {
    this.st.mounted = new Date().getTime();
    this.st.bm = this.st.mounted - this.st.beforeMount;
    // console.log(this.st);
  },

  beforeMount() {
    this.st.beforeMount = new Date().getTime();

    if (this.config?.status === 1) {

      // console.log("running " + this.config.code);

      this.p = this.row[this.config.code] ? this.row[this.config.code] : this.config.defaultOption
      this.pSub = this.row[this.config.subCode] ? this.row[this.config.subCode] : this.config.subItemDefaultOption;
    }
    if (this.$attrs.readOnly) {
      if (!this.p || this.p === '') {
        this.config.subItemDescription = ''
      }
      // if (this.config?.status === 0) {
      //   this.p = "未做"
      //
      // }
    }


  },


  data() {
    return {
      p: "",
      pSub: ""
    }
  },
  computed: {
    formKey() {
      return this.$store.state.formKey;
    }
  },


  watch: {
    p(newValue, oldValue) {
      let key = `${this.config.code}`;
      let event = {};
      event[key] = newValue;
      this.$emit("change", event);
    },
    pSub(newValue, oldValue) {
      let key = `${this.config.subCode}`;
      let event = {};
      event[key] = newValue;
      this.$emit("change", event);
    },

    formKey(n, o) {
      this.p = this.config.defaultOption
      this.pSub = this.config.subItemDefaultOption;


    }
  },
  methods: {
    setValue(value) {
      this.p = value;
    }
  },
}
</script>

<style scoped>


span {
  white-space: nowrap;

}


.none {
  display: none;
}

.block {
  display: block;
}

.one {
  flex: 1;
}

.two {
  flex: 2;
}

.el-select {
  width: 100% !important;
}

</style>
